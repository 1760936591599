import React, { useState } from 'react';
import './ContactUs.css';
import backArrow from "./Arrow_left.png";
import { db } from "./../firebase-config.js"
import {collection, addDoc, doc, getDoc} from "firebase/firestore"
import { useLocation, useNavigate } from "react-router-dom";

export const ContactUs = () => {

    const { state } = useLocation();
    const navigate = useNavigate();

    const feedbackCollectionsRef = collection(db, "feedback-messages");
    const mailRef = collection(db, "mail");
    const [newID , setNewID] = useState("");
    const [newMessage, setNewMessage] = useState("");

    //Write to Firestore in feedback-messages
    const createFeedback = async () => {
        await addDoc(feedbackCollectionsRef, {email_id: newID, message: newMessage, time: new Date()});
    }
    const createFeedbackMail = async () => {
        await addDoc(mailRef, {to: ["dev.potexpress@gmail.com"], message: {
            subject: "New Feedback Message from " + String(newID),
            text: newMessage,
            html: "This is the <code>HTML</code> section of the email body.",
          }});
    }

    const submitResponse = () => {
        //submit contact us text fields to database
        let contact_us_email = document.getElementById("emailIDFieldContact").value;
        let contact_us_message = document.getElementById("feedbackMessageContact").value;

        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (contact_us_email == "" || contact_us_message == "") {
            alert("Please fill in all fields.");
            return;
        }
        if (!re.test(contact_us_email)) {
            alert("Please provide a valid email address.");
            return;
        }

        // createFeedbackMail();
        createFeedback();
        alert("Thank you for your response. We will reply back to you within 24 hours.")
    }

    const back = async () => {
        // go to previous page
        let userid = state.uID;
        const docRef = doc(db, "users", userid);
      try { 
        const docSnap = await getDoc(docRef);
        if (docSnap.data().sharer) {
          navigate("/sharerHome", { state: {uID: userid} });
        } else if (!docSnap.data().sharer) {
          navigate("/shareeHome", { state: {uID: userid} });
        }
      } catch(error) { 
        console.log(error);
        navigate("/");
      }
    };

    return(
        <div id='contactUsScreen'>

            <div id='headerContact'>
                <button id='backButtonContact' onClick={back}>
                    <img id="backPic_CU" src={backArrow} alt="Back"/>
                </button>
                <p id='pageTitleContact'>Contact Us</p>
            </div>

            <div id='contactUsMessage'>
                <p id="aboutContactUs">We are happy to help you. Please share your problem with us. We will definitely help you and reply back to 
                you within 24 hours.</p>
            </div>

            <div id='emailIDContact' className='emailIDAreaContact'>
                <p className='inputEmailContact'>Your Email-ID :</p>
                <input id="emailIDFieldContact" type="text" defaultValue="" onChange={(event) => {
                    setNewID(event.target.value)
                }}/>
            </div>

            <div id='feedbackContact' className='feedbackAreaContact'>
                <p className='inputFeedbackContact'>Problem or Feedback :</p>
                <textarea id="feedbackMessageContact" placeholder="Describe your concern here..." rows={10} onChange={(event) => {
                    setNewMessage(event.target.value)
                }}/>
            </div>

            <input id='submitButtonContact' type="button" value="SUBMIT" onClick={submitResponse}/>
        </div>
    );
}