import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './LoginPage.css'
import logo from "./Green-2.jpeg"
import { db } from "./../firebase-config.js"
import { getDoc, doc } from "firebase/firestore"
import { signInWithGoogle } from "./../firebase-config.js"
import { auth } from './../firebase-config.js';
import { query, collection, where, getDocs } from "firebase/firestore";
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

export const LoginPage = () => {

    const [emailError, setEmailError] = useState(0);
    let emailContinue = 0;
    const [passwordError, setPasswordError] = useState(0);
    let passwordContinue = 0;
    const navigate = useNavigate();

    function handleLoginClicked() {
        if (document.getElementById("loginEmailBox").value.length == 0) {
            setEmailError(1);
            emailContinue = 1;
        } else {
            setEmailError(0);
        }
        if (document.getElementById("loginPasswordBox").value.length < 6) {
            setPasswordError(1);
            passwordContinue = 1;
        } else {
            setPasswordError(0);
        }
        if (passwordContinue == 0 && emailContinue == 0) {
            login();
        }
    }

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      var userid = user.user.uid;
      const docRef = doc(db, "users", userid);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.data().sharer) {
          let qSell = query(collection(db, "orders"), where("seller", "==", userid), where("completed", "==", false), where("accepted", "==", true));
          let sells = await getDocs(qSell);
          if (sells.docs.length > 0) {
            let order = sells.docs[0].data();
            order.id = sells.docs[0].id;
            navigate("/sharerOrderPage", { state: {uID: userid, order:order.id }});
            return;
          }
          navigate("/sharerHome", { state: {uID: userid} });
        } else if (!docSnap.data().sharer) {
          let qBuy = query(collection(db, "orders"), where("buyer", "==", userid), where("completed", "==", false), where("accepted", "==", true));
          let buys = await getDocs(qBuy);
          if (buys.docs.length > 0) {
            let order = buys.docs[0].data();
            order.id = buys.docs[0].id;
            let sellerID = order.seller;
            let orderID = order.id;
            let sellerNum = order.sellerPhone;
            navigate("/shareeOrderPage", { state: {uID: userid, sellerID: sellerID, orderID: orderID, sellerNum: sellerNum}});
            return;
          }
          navigate("/shareeHome", { state: {uID: userid} });
        }
      } catch(error) { 
        console.log(error);
      }
    } catch (error) {
      console.log(error.message);
      alert("Your email or password was not correct");
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  const [user, setUser] = useState({});

  useEffect(() => {
    logout();
    onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
    });
}, [])

    return(
        <div>
            <div><img id="potLogoLogin" src = {logo} alt = "Logo 2" style = {{position:'absolute', top: '7vh', left: '5vw', alignSelf: 'center'}}/></div>
            <div id="loginHeader">Login!</div>

            <div> <input id="loginEmailBox" className="LoginPage" type="text" placeholder="Email Address" onChange={(event) => {
            setLoginEmail(event.target.value);
          }}/> </div>
            <div>
                {emailError == 1 && <div id="loginEmailError">Please enter email address</div>}
            </div>

            <div> <input id="loginPasswordBox" className="LoginPage" type="password" placeholder="Password" onChange={(event) => {
            setLoginPassword(event.target.value);
          }}/> </div>
            <div>
                {passwordError == 1 && <div id="loginPasswordError">Password must be at least 6 characters</div>}
            </div>

            <div>
                <button onClick={handleLoginClicked} id="loginButton">LOGIN</button>
            </div>

            <div id="passwordResetLink">Forgot your password? <Link to="/forgotpassword">Reset Here</Link></div>

            {/* <div id="continueOptionLogin">Or continue with</div>
            <div id = "googleButton"><button onClick={signInWithGoogle} type="button" className="login-with-google-btn" >Sign in with Google</button></div> */}

            <div id="loginNoAccount">Don't have an account? <Link to="/signUp">Sign Up</Link></div>
            
        </div>
    );
}