import React from 'react';
import './AddStrain.css'
import backArrow from "./Arrow_left.png";
import plus from "./black_plus.png"
import { useNavigate, useLocation } from "react-router-dom";
import { db, storage } from "./../firebase-config.js"
import {collection, addDoc, getDoc, updateDoc, doc} from "firebase/firestore"
import { useState } from 'react';
import { ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";

export const AddStrain = () => { 

    const navigate = useNavigate();
    const { state } = useLocation();
    const user_id = state.uID;
    const [value, setValue] = useState(25);
    const userDoc = doc(db, "users", user_id);

    const backBtn = () => {
        navigate("/manageStrains", {state:{uID:user_id}});
    };

    const strainsCollectionsRef = collection(db, `users/${user_id}/strains`);
    const [image, setImage] = useState(null);
    const [imgFile, setImgFile] = useState(null);
    const [newName, setNewName] = useState("");
    const [newPrice, setNewPrice] = useState(0);
    const [newTHC, setNewTHC] = useState(0);
    const [newType, setNewType] = useState("indica");
    const [newWeight, setNewWeight] = useState("three");
    const [newDescription, setNewDescription] = useState("");

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setImgFile(event.target.files[0]);
            console.log("ifffff")
        }
        console.log("image change")
    };

    const createStrain = async () =>  {
        let strainID = await addDoc(strainsCollectionsRef, {name: newName, price: newPrice, 
            thc: newTHC, type: newType,  weight: newWeight, description: newDescription, active: true});

        let user = await getDoc(userDoc);
        if (user.data().activeStrains >= 0) {
            await updateDoc(userDoc, {activeStrains: user.data().activeStrains + 1});
        } else {
            await updateDoc(userDoc, {activeStrains: 1});
        }

        if (imgFile) {
            const imgRef = ref(storage, `images/${user_id}/strains/${strainID.id}_${v4()}`);
            uploadBytes(imgRef, imgFile);
        }
        navigate("/sharerHome", {state:{uID: user_id}});
    };
    
    const [sliderValue, setSliderValue] = useState(50);
    
    function handleSliderChange(event) {
        setNewTHC(parseInt(event.target.value/2))
        setSliderValue(event.target.value);
    }
    return (
        <div id="phoneFrame">
            <div id='headerAS'>
                <button id='backButtonAS' onClick={backBtn}>
                    <img id="backPic_AS" src={backArrow} alt="Back"/>
                </button>
                <p id='pageTitleAS'>Add Strain</p>
            </div>
            {/* <div></div> */}
            <div>
                <div id = "word1_as">This pot will be shown to sharee once you are online.</div>
            </div>

            {/* Images */}
            <div id="picGroup_as">
                <label id="plus_as" for="file-input">
                    <img id="plus_button_as" src={plus} alt="Default"/>
                </label>
                <input id="file-input" type="file" accept='image/*' onChange={onImageChange}/>
                {image != null && 
                <div id="picBox_as">
                    <img id="strainPic" src={image} alt="Default"/>
                </div>
                }              
            </div>

            {/* Pot Name */}
            <div>
                <div id = "word2">Name of this pot :</div>
                <div id= "box">
                    <input type="text" onChange={(event) => {setNewName(event.target.value)}}/>
                </div>
            </div>
            {/* Pot Strain Type */}
            <div>
                <div id = "word2">Pot strain type :</div>
                <div id= "box">
                <select name="strain" id="strain" onChange={(event) => {setNewType(event.target.value)}}>
                    <option value="indica">Indica</option>
                    <option value="hybrid">Hybrid</option>
                    <option value="sativa">Sativa</option>
                </select>
                </div>
            </div>
            {/* Pot weight */}
            <div>
                <div id = "word2">Pot weight :</div>
                <div id= "box">
                <select name="weight" id="weight" onChange={(event) => {setNewWeight(event.target.value)}}>
                    <option value="three">3 Gram(s)</option>
                    <option value="two">2 Gram(s)</option>
                    <option value="one">1 Gram(s)</option>
                    <option value="quarter">Quarter</option>
                    <option value="Half">Half</option>
                    <option value="ounce">1 Ounce</option>
                    <option value="eighth">8th</option>
                </select>
                </div> 
            </div>
            {/* THC% */}
            <div>
                <div id = "word2">THC:</div>
                <div id= "box2">
                    <div id= "word3">0%</div>
                        <div id='box3'>
                            <div id = "slidecontainer"> 
                                <form>
                                    <input type="range" min="1" max="100" value={sliderValue} onChange={handleSliderChange} style={{ background: `linear-gradient(to right, rgb(60,60,60) ${sliderValue}%, gray ${sliderValue}%)` }}/>
                                </form>
                            </div>
                        </div>
                    <div id= "word3">{newTHC}%</div>
            </div>
            </div>
            {/* Price */}
            <div>
                <div id = "word2">Price of this strain :</div>
                <div id= "box">
                    <input type="number"  id='price_as' onChange={(event) => {setNewPrice(parseFloat(event.target.value))}}></input> 
                </div>
            </div>
            {/* Description */}
            <div>
                <div id = "word2">Description (About, Taste, Smell, Effect...) :</div>
                <div id= "box">
                    <input type="text" onChange={(event) => {setNewDescription(event.target.value)}}></input>
                </div>
            </div>
            {/* Save Button */}
            <div>
                
                <div id= "box4">
                    <div id = "word4"  onClick={createStrain}>SAVE STRAIN</div>
                </div>
            </div>

        </div>
        );
    }

   