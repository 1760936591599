import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import './SelectUserTypePage.css'
import logo from "./Green-2.jpeg"
import backArrow from "./Arrow_left.png";
import countries_states from "./countries-states.json";
import Select from 'react-select';
import { db } from "./../firebase-config.js"
import { updateDoc, doc} from "firebase/firestore"

export const SelectUserTypePage = () => {
    const [border1, setBorder1] = useState(1);
    const [border2, setBorder2] = useState(0);
    const [sharer, setSharer] = useState(true);
    const { state } = useLocation();
    const navigate = useNavigate();
    let existing_country = "USA";
    let existing_state = "CO";
    const [country, setCountry] = useState("USA");
    const [currState, setCurrState] = useState("");

    function nextClicked() {
        updateUser();
        navigate("/uploadPFP", { state: {uID: state.uID, name: state.name, email: state.email, pwd: state.pwd}});
    }

    const updateUser = async () => {
        const userDoc = doc(db, "users", state.uID);
        const newFields = {
            country: country,
            state: currState,
            sharer: sharer,
            ratings: [0,0,0,0,0,0],
            activeStrains: 0,
            active: false,
            paid: false,
            delivery: "Both"
        };
        await updateDoc(userDoc, newFields);
    }

    function handleSharerBoxClicked() {
        setBorder1(1);
        setBorder2(0);
        setSharer(true);
    }

    function handleShareeBoxClicked() {
        setBorder1(0);
        setBorder2(1);
        setSharer(false);
    }

    function backClick() {
        navigate("/signUp");
    }

    const [states_dropdown, setMyArray] = useState(null);
    var statesData = countries_states.find(item => item.iso3 === existing_country).states;
    var simple_states = [];
    statesData.forEach(state => {
        let updated_state = { value: state.state_code, label: state.name };
        simple_states.push(updated_state);
    });

    var simple_countries = [];
    countries_states.forEach(country => {
        let updated_country = { value: country.iso3, label: country.name };
        simple_countries.push(updated_country);
    });

    const onCountryChange = (value, actionMeta) => {
        let new_country = value.value;
        let found_states = countries_states.find(item => item.iso3 === new_country).states;
        let simple_new_states = [];
        found_states.forEach(state => {
            let updated_state = { value: state.state_code, label: state.name };
            simple_new_states.push(updated_state);
        });
        if (simple_new_states.length === 0) {
            simple_new_states.push({ value: "N/A", label: "Not Applicable"});
        }
        setCountry(new_country);
        setMyArray(simple_new_states);
        document.getElementById("selectCountryGroup").children[2].children[0].children[0].textContent = simple_new_states[0].label;
        setCurrState(simple_new_states[0].value);
    };

    return(
        <div>
            <div id="backArrowDiv">
                <button id='selectUserBackButton' onClick={backClick}>
                    <img id="selectUserBackPic" src={backArrow} alt="Back"/>
                </button></div>
        <div><img id="potLogo2" src = {logo} alt = "Logo 2" style = {{position:'absolute', top: '7vh', left: '5vw', alignSelf: 'center'}}/></div>
        <div id="selectUserHeader">Select User Type!</div>

        

        <div onClick={handleSharerBoxClicked}>
        {border1 == 1 && <div id="sharerUserTypeBox" style={{border: "0.3vh solid black"}}></div>}
        {border1 == 0 && <div id="sharerUserTypeBox"></div>}
            <div id="selectUserSharerBoxLabel">
            <h1 style = {{fontSize:"2.5vh"}}>Sharer</h1>
            <p>Give pot to those that want it</p>
            </div>
        </div>

        <div  onClick={handleShareeBoxClicked}>
        {border2 == 1 && <div id="shareeUserTypeBox" style={{border: "0.3vh solid black"}}></div>}
        {border2 == 0 && <div id="shareeUserTypeBox"></div>}
            <div id="selectUserShareeBoxLabel">
            <h1 style = {{fontSize:"2.5vh"}}>Sharee</h1>
            <p>Get pot from those who have it</p>
            </div>
        </div>

        <div id="userTypeCountryGroup">
                <Select onChange={onCountryChange}
                        id='selectCountryGroup'
                        value={simple_countries.value}
                        options={simple_countries}
                        defaultValue={simple_countries.find(item => item.value === existing_country)}
                        menuPlacement='top'
                        maxMenuHeight={200}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                top: '-12vh',
                                background: '#eaeaea',
                                height: '6vh',
                                marginLeft: '4%',
                                marginRight: '4%',
                                marginTop: '2.5%',
                                textAlign: 'left',
                                paddingLeft: '2.5%',
                                fontSize: '1.25em',
                                color: '#1e1e1e'
                            }),
                        }}
                />
            </div>

            <div id='userTypeStateGroup'>
                <Select id='selectStateGroup' onChange={(value) => {setCurrState(value.value)}}
                        value={states_dropdown == null ? simple_states.value : states_dropdown.value}
                        options={states_dropdown == null ? simple_states : states_dropdown}
                        defaultValue={states_dropdown == null ? simple_states.find(item => item.value === existing_state) : states_dropdown[0]}
                        menuPlacement='top'
                        // maxMenuHeight={200}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: '6vh',
                                top: '-12vh',
                                background: '#eaeaea',
                                marginLeft: '4%',
                                marginRight: '4%',
                                marginTop: '2.5%',
                                textAlign: 'left',
                                paddingLeft: '2.5%',
                                fontSize: '1.25em',
                                color: '#1e1e1e'
                            }),
                        }}
                />
            </div>

        {/* <div>
            <select id="selectedUserCountryBox">
                <option value="US">United States</option>
            </select>
        </div>

        <div> 
            <select  id="selectUserStateBox">
                <option value="AL">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="IL">Illinois</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NV">Nevada</option>
                <option value="OR">Oregon</option>
                <option value="RI">Rhode Island</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
            </select>
        </div> */}

        <div> <button onClick={nextClicked} id="selectUserNextButton">Next</button> </div>
        
        </div>
    );
}