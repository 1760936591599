import React from 'react';
import './PlayStoreLink.css';
import { useNavigate } from 'react-router-dom';

export const PlayStoreLink = () => {
    const navigate = useNavigate();
    return(
        <div>
            <div>
                <a href="https://play.google.com/store/apps/details?id=com.potexpress.potexpress&hl=en_US&gl=US">
                    <img id="playStoreImage" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/800px-Google_Play_Store_badge_EN.svg.png" alt="Play Store Logo"/>
                </a>
            </div>
            <img id="appleImage" onClick={() => {navigate("/login")}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1667px-Apple_logo_black.svg.png" alt="Apple Logo"/>
            <div>

            </div>
        </div>
    );
}