import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './SignUpPage.css'
import logo from "./Green-2.jpeg"
import backArrow from "./Arrow_left.png";
import { signInWithGoogle } from "./../firebase-config.js"
import { db } from "./../firebase-config.js"
import { doc, setDoc } from "firebase/firestore"
import { auth } from './../firebase-config.js';
import { createUserWithEmailAndPassword } from "firebase/auth";

export const SignUpPage = () => {

    const [nameError, setNameError] = useState(0);
    let nameContinue = 0;
    const [emailError, setEmailError] = useState(0);
    let emailContinue = 0;
    const [ageError, setAgeError] = useState(0);
    let ageContinue = 0;
    const [passwordError, setPasswordError] = useState(0);
    let passwordContinue = 0;
    const [passwordConfirmError, setPasswordConfirmError] = useState(0);
    let passwordConfirmContinue = 0;
    const navigate = useNavigate();

    const [name, setName] = useState("");

    function backClick() {
        navigate("/login");
    }

    function handleNextButton() {
        if (document.getElementById("signUpNameBox").value.length === 0) {
            setNameError(1);
            nameContinue = 1;
        } else {
            setNameError(0);
        }
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (document.getElementById("signUpEmailBox").value.length === 0 || (!re.test(document.getElementById("signUpEmailBox").value))) {
            setEmailError(1);
            emailContinue = 1;
        } else {
            setEmailError(0);
        }
        if (document.getElementById("loginAgeBox").value.length === 0) {
            setAgeError(1);
            ageContinue = 1;
        } else {
            setAgeError(0);
        }
        if (document.getElementById("signUpPasswordBox").value.length === 0) {
            setPasswordError(1);
            passwordContinue = 1;
        } else {
            setPasswordError(0);
        }
        if (document.getElementById("signUpPasswordConfirmBox").value.length === 0 || 
        document.getElementById("signUpPasswordConfirmBox").value != document.getElementById("signUpPasswordBox").value) {
            setPasswordConfirmError(1);
            passwordConfirmContinue = 1;
        } else {
            setPasswordConfirmError(0);
        }
        if ((nameContinue === 0) && (emailContinue === 0) && (ageContinue === 0) && (passwordContinue === 0) && (passwordConfirmContinue === 0)) {
            register();
        }
    }

    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");

    const register = async () => {
        try {
          await createUserWithEmailAndPassword(
            auth,
            registerEmail,
            registerPassword
          );
          await setDoc(doc(db, "users", auth.currentUser.uid), {
            name: document.getElementById("signUpNameBox").value,
            email: document.getElementById("signUpEmailBox").value,
            age: document.getElementById("loginAgeBox").value,
            location: ""
          });
          navigate("/selectUserType", { state: {uID: auth.currentUser.uid, name: name, email: registerEmail, pwd: registerPassword } } );
        } catch (error) {
          alert(error);
          return;
        }
      };

    return(
        <div id="phoneFrame">
        <div id="backArrowPlaceHolder">
            <button id='signUpBackButton' onClick={backClick}>
                    <img id="signUpBackPic" src={backArrow} alt="Back"/>
            </button>
        </div>
        <div><img id="potLogo" src = {logo} alt = "Logo 2" style = {{position:'absolute', top: '7vh', left: '5vw', alignSelf: 'center'}}/></div>
        <div id="signUpHeader">Sign Up!</div>

        <div> <input id="signUpNameBox" className="SignUpPage" type="text" placeholder="Enter Name" onChange={(event) => setName(event.target.value)}/> </div>
        <div>
        {nameError == 1 && <div id="signUpNameError">Please enter full name</div>}
        </div>
        
        <div> <input id="loginAgeBox" className="SignUpPage" type="number" placeholder="Age"/> </div>
        <div>
        {ageError == 1 && <div id="signUpAgeError">Please enter your age</div>}
        </div>

        <div> <input id="signUpEmailBox" className="SignUpPage" type="text" placeholder="Enter Email" onChange={(event) => {
            setRegisterEmail(event.target.value);
          }}/> </div>
        <div>
        {emailError == 1 && <div id="signUpEmailError">Please enter your email address</div>}
        </div>

        <div> <input id="signUpPasswordBox" className="SignUpPage" type="text" placeholder="Enter Password"/> </div>
        <div>
        {passwordError == 1 && <div id="signUpPasswordError">Please enter your password</div>}
        </div>

        <div> <input id="signUpPasswordConfirmBox" className="SignUpPage" type="text" placeholder="Confirm Password" onChange={(event) => {
            setRegisterPassword(event.target.value);
          }}/> </div>
        <div>
        {passwordConfirmError == 1 && <div id="signUpPasswordConfirmError">Please confirm your password</div>}
        </div>

        <div id="termsDescription">By continuing, I accept the Terms of Service, Guidelines and Privacy Policy</div>

        <div>
            <button onClick={handleNextButton} id="loginNextButton">Next</button>
        </div>

        {/* <div id="continueOption">Or continue with</div>

        <div id = "googleButton2"><button onClick={signInWithGoogle} type="button" className="login-with-google-btn2" >Sign up with Google</button></div> */}

        <div id="signUpNoAccount">
            <div>Already have an account? <div id="signUpNoAccountLogin"><Link to="/login">Login</Link></div></div>
        </div>
        </div>
    );
}