import React from 'react';
import './ManageDelivery.css';
import backArrow from "./Arrow_left.png";
import { db } from "./../firebase-config.js";
import { updateDoc, doc } from "firebase/firestore";
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";

export const ManageDelivery = () => { 

    const { state } = useLocation();
    const navigate = useNavigate();
    const [newDelivery, setNewDelivery] = useState("");
    const [currentID, setUID] = useState("");
    const tooltipRef = useRef();
    const openTooltip = () => tooltipRef.current.open();
    const closeTooltip = () => tooltipRef.current.close();

    const updateUser = async () => {
        const userDoc = doc(db, "users", currentID)
        const newFields = {delivery: newDelivery}
        await updateDoc(userDoc, newFields)
        openTooltip();
    }

    useEffect(() => {
        setUID(state.uID);
    }, []);

    function backClicked() {
        navigate("/sharerHome", {state: {uID: state.uID}});
    }

    return (
        <div id="phoneFrame">

            <div id='headerMD'>
                <button id='backButtonMD' onClick={backClicked}>
                    <img id="backPic_MD" src={backArrow} alt="Back"/>
                </button>
                <p id='pageTitleMD'>Choose Delivery Type</p>
            </div>

            {/* Description */}
            <div id = "description">
                <div id = "word2_md"> This is the option the sharee will see. 
                If you select both that means you can accept delivery and pick up both</div>
            </div>

            {/* Delivery */}
            <label id = "mode_md"> 
                <input type="radio" name="styles" id="accent" defaultChecked value={"Delivery"} onChange={(event) => {setNewDelivery(event.target.value)}}></input>
                <div id="word">Delivery</div>
            </label>
            {/* Pick up */}
            <label id = "mode_md"> 
                <input type="radio" name="styles" id="accent" value={"Pick up"} onChange={(event) => {setNewDelivery(event.target.value)}}></input>
                <div id="word">Pick up</div>
            </label>
            {/* Both */}
            <label id = "mode_md"> 
                <input type="radio" name="styles" id="accent" value={"Both"} onChange={(event) => {setNewDelivery(event.target.value)}}></input>
                <div id="word">Both (Pick up & Delivery)</div>
            </label>
            {/* Save */}
            <div id = "save_md"> 
                <div id="word3_md" onClick={updateUser}>SAVE</div>
            </div>

            <Popup
                contentStyle={{borderRadius: "10px", padding: "2%"}}
                ref={tooltipRef}
                trigger={<div id="statusPopup"></div>}
                arrow={false}
                position="top center"
                closeOnDocumentClick={true}
                onOpen={() => {
                    const timer = setTimeout(() => {
                        closeTooltip();
                    }, 3500);
                    return () => clearTimeout(timer);
                }}
            >
                <div>Delivery type successfully updated</div>
            </Popup>


        </div>
        );
  }