import React, { useEffect } from 'react';
import './EditStrain.css'
import backArrow from "./Arrow_left.png";
import plus from "./black_plus.png"
import { useNavigate, useLocation } from "react-router-dom";
import { db, storage } from "./../firebase-config.js"
import { updateDoc, doc} from "firebase/firestore"
import { useState } from 'react';
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 } from "uuid";

export const EditStrain = () => { 

    const navigate = useNavigate();
    const { state } = useLocation();
    const [user_id, setUID] = useState(null);
    const [strain, setStrain] = useState(null);

    const backBtn = () => {
        navigate("/manageStrains", {state:{uID:user_id}});
    };

    const [image, setImage] = useState(null);
    const [imgFile, setImgFile] = useState(null);

    const [newName, setNewName] = useState("");
    const [newPrice, setNewPrice] = useState(0);
    const [newTHC, setNewTHC] = useState(0);
    const [newType, setNewType] = useState("indica");
    const [newWeight, setNewWeight] = useState("three");
    const [newDescription, setNewDescription] = useState("");

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setImgFile(event.target.files[0]);
        }
    };

    useEffect(() => {
        setUID(state.uID);
        setStrain(state.strain);
        setNewName(state.strain.name);
        setNewTHC(state.strain.thc);
        setNewType(state.strain.type);
        setNewWeight(state.strain.weight);
        setNewDescription(state.strain.description);
        setNewPrice(state.strain.price);
        listAll(ref(storage, `images/${state.uID}/strains/`)).then((response) => {
            let img = response.items.find(item => item.name.split("_")[0] === `${state.strain.id}`);
            getDownloadURL(img).then((url) => {
                setImage(url);
            }).catch(() => {
                console.log("Error downloading image!");
            });
        }).catch(() => {
            console.log("No images to find!");
        });
    }, []);

    //Update
    const updateStrain = async () => {
        const userDoc = doc(db, `users/${user_id}/strains`, strain.id)
        const newFields = {name: newName, price: newPrice, 
            thc: newTHC, type: newType,  weight: newWeight, description: newDescription, active: true}
        await updateDoc(userDoc, newFields);
        if (imgFile) {
            const img_ref = ref(storage, `images/${user_id}/strains/`);
            listAll(img_ref).then((response) => {
                let img_found = false;
                response.items.forEach((item) => {
                    if (!img_found && item.name.back("_")[0] === strain.id) {
                        deleteObject(item);
                        img_found = true;
                    }
                });
            }).catch();
            const imgRef = ref(storage, `images/${user_id}/strains/${strain.id}_${v4()}`);
            uploadBytes(imgRef, imgFile);
        }
        navigate("/manageStrains", {state:{uID:user_id}});
    }

   const [sliderValue, setSliderValue] = useState(50);
    
    function handleSliderChange(event) {
        setNewTHC(parseInt(event.target.value/2))
        setSliderValue(event.target.value);
    }
    return (
        <div id="phoneFrame">
            <div id='headerES'>
                <button id='backButtonES' onClick={backBtn}>
                    <img id="backPic_ES" src={backArrow} alt="Back"/>
                </button>
                <p id='pageTitleES'>Edit Strain</p>
            </div>
            {/* <div></div> */}
            <div>
                <div id = "word1_es">This pot will be shown to sharee once you are online.</div>
            </div>
            {/* Images */}
            <div id="picGroup_es">
                <label id="plus_es" for="file-input" onClick={onImageChange}>
                    <img id="plus_button_es" src={plus} alt="Default"/>
                </label>
                <input id="file-input" type="file" accept='image/*' onChange={onImageChange}/>
                <div id="picBox_es">
                    <img id="strainPic_es" src={image} alt="Default" />
                </div>                
            </div>
            
            {/* Pot Name */}
            <div>
                <div id = "word2">Name of this pot :</div>
                <div id= "box">
                    <input type="text" placeholder={strain?.name} onChange={(event) => {setNewName(event.target.value)}}/>
                </div>
            </div>
            {/* Pot Strain Type */}
            <div>
                <div id = "word2">Pot strain type :</div>
                <div id= "box">
                {strain && <select name="strain" defaultValue={strain?.type} id="strain" onChange={(event) => {setNewType(event.target.value)}}>
                    <option value="indica">Indica</option>
                    <option value="hybrid">Hybrid</option>
                    <option value="sativa">Sativa</option>
                </select>}
                </div>
            </div>
            {/* Pot weight */}
            <div>
                <div id = "word2">Pot weight :</div>
                <div id= "box">
                {strain && <select name="weight" defaultValue={strain?.weight} id="weight" onChange={(event) => {setNewWeight(event.target.value)}}>
                    <option value="three">3 Gram(s)</option>
                    <option value="two">2 Gram(s)</option>
                    <option value="one">1 Gram(s)</option>
                    <option value="quarter">Quarter</option>
                    <option value="Half">Half</option>
                    <option value="ounce">1 Ounce</option>
                    <option value="eighth">8th</option>
                </select>}
                </div> 
            </div>
            {/* THC% */}
            <div>
                <div id = "word2">THC:</div>
                <div id= "box2">
                    <div id= "word3">0%</div>
                        <div id='box3'>
                            <div id = "slidecontainer"> 
                                <form>
                                    <input type="range" min="1" max="100" value={sliderValue} onChange={handleSliderChange} style={{ background: `linear-gradient(to right, rgb(60,60,60) ${sliderValue}%, gray ${sliderValue}%)` }}/>
                                </form>
                            </div>
                        </div>
                    <div id= "word3">{newTHC}%</div>
            </div>
            </div>
            {/* Price */}
            <div>
                <div id = "word2">Price of this strain :</div>
                <div id= "box">
                    {strain && <input type="number" placeholder={strain?.price} id='price_as' onChange={(event) => {setNewPrice(parseFloat(event.target.value))}}></input>}
                </div>
            </div>
            {/* Description */}
            <div>
                <div id = "word2">Description (About, Taste, Smell, Effect...) :</div>
                <div id= "box">
                    {strain && <input type="text" placeholder={strain?.description} onChange={(event) => {setNewDescription(event.target.value)}}></input>}
                </div>
            </div>
            {/* Save Button */}
            <div>
                
                <div id= "box4">
                    <div id = "word4"  onClick={() => {updateStrain()}}>SAVE STRAIN</div>
                </div>
            </div>

        </div>
        );
    }

   