import React, {useState, useEffect} from 'react'
 import { CgMenuRound } from 'react-icons/cg';
 import "../App.css";
 import './Navbar.css';
 import * as AiIcons from "react-icons/ai";
 import { Link } from "react-router-dom";
 import { SidebarDataSharer } from "./SidebarData";
 import "../App.css";
 import { IconContext } from "react-icons";
 import logo from "./Green-2.jpeg"
 import { db, storage } from "./../firebase-config.js";
 import { getDoc, updateDoc, doc } from "firebase/firestore";
 import { useNavigate, useLocation } from "react-router-dom";
 import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";
 import { v4 } from "uuid";

 function NavbarSharer({userid}) {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    const location = useLocation();
    const [user_id, setUID] = useState("");
    const navigate = useNavigate();

    // working data
    const [name, setName] = useState("Enter name");
    const [email, setEmail] = useState("Enter email");
    const [image, setImage] = useState(logo);
    const [imgFile, setImgFile] = useState(null);

    useEffect(() => {
    async function fetchData() {
        setUID(location.state.uID);
        const user_ref = doc(db, "users", location.state.uID);
        const user = await getDoc(user_ref);

        if (!user.exists()) {
            alert("Something went wrong!");
        }

        // sets working values to db values
        setName(user.data().name);
        setEmail(user.data().email);

        // download user's pfp
        listAll(ref(storage, `images/${location.state.uID}/`)).then((response) => {
            let img = response.items.find(item => item.name.split("_")[0] === "pfp");
            getDownloadURL(img).then((url) => {
                setImage(url);
            }).catch(() => {
                console.log("Error downloading image!");
            });
        }).catch(() => {
            console.log("No images to find!");
        });
    }
    fetchData();
  }, []);
  
  const [startTouchX, setStartTouchX] = useState(null);

  const handleTouchStart = (e) => {
    setStartTouchX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (startTouchX) {
      const currentTouchX = e.touches[0].clientX;
      const distanceX = startTouchX - currentTouchX;
      const threshold = 100; // adjust this to change the sensitivity of the swipe
      if (distanceX > threshold) {
        setSidebar(false);
      } else if (distanceX < -threshold) {
        setSidebar(true);
      }
    }
  };

  const handleTouchEnd = (e) => {
    setStartTouchX(null);
  };

   return (
     <>
       <IconContext.Provider value={{ color: "undefined" }}>
         <div className="navbar">
           <Link to="#" className="menu-bars">
             <CgMenuRound onClick={showSidebar}/>
           </Link>
         </div>
         <nav className={sidebar ? "nav-menu active" : "nav-menu"}  onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
         <div className="sbinfo">
              <img id="potLogo_sb" src = {image} alt = "Logo 2"/>
              <div className="userName">{name}</div>
              <div className="userEmail">{email}</div>
          </div>
           <ul className="nav-menu-items" onClick={showSidebar}>
             <li className="navbar-toggle">
               <Link to="#" className="menu-bars">
                 {/* <AiIcons.AiOutlineClose /> */}
               </Link> 
             </li>
             {SidebarDataSharer.map((item, index) => {
               return (
                 <li key={index} className={item.cName}>
                   <Link to={item.path} id="navBarBox" state={{uID: userid}}>
                     {item.icons}
                     <span>{item.title}</span>
                   </Link>
                 </li>
               );
             })}
           </ul>
         </nav>
       </IconContext.Provider>
     </>
   );
 }

 export default NavbarSharer;