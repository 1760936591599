import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './ForgotPasswordPage.css';
import logo from "./Green-2.jpeg";
import backArrow from "./Arrow_left.png";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export const ForgotPasswordPage = () => {

    const [sendEmailError, setSendEmailError] = useState(0);
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const auth = getAuth();
  
    const triggerResetEmail = async () => {
      console.log(email)
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent")
    }

    function backClick() {
        navigate("/login");
    }

    return(
        <div>
        <div id="passwordBackDiv">
            <button id="passwordBackButton" onClick={backClick}>
                    <img id="passwordBackPic" src={backArrow} alt="Back"/>
                </button>
        </div>
        <div><img id="potLogoFP" src = {logo} alt = "Logo 2" style = {{position:'absolute', top: '7vh', left: '5vw', alignSelf: 'center'}}/></div>
        <div id="FPHeader">Forgot your password!</div>

        <div id="FPDescription">Please enter your register email address. We will verify you through OTP</div>

        <div> <input id="FPBox" className="ForgotPasswordPage" type="text" placeholder="Email Address" onChange={(e) => setEmail(String(e.target.value))}/> </div>
        <div>
        {sendEmailError == 1 && <div id="FPEmailError">Please enter your correct email</div>}
        </div>

        <div>
            <button onClick={triggerResetEmail} id="FPSubmitButton">Submit</button>
        </div>
        
        </div>
    );
}
export default ForgotPasswordPage;