import { Routes, Route } from "react-router-dom";
import './App.css';
import { SignUpPage } from './components/SignUpPage';
import { SelectUserTypePage} from './components/SelectUserTypePage';
import { UploadPFPPage } from './components/UploadPFPPage';
import { ManageStrains } from './components/ManageStrains';
import { LoginPage } from './components/LoginPage';
import { ForgotPasswordPage } from './components/ForgotPasswordPage';
import { ManageDelivery } from './components/ManageDelivery';
import { AddStrain } from './components/AddStrain';
import { EditStrain } from './components/EditStrain';
import { EditProfile } from './components/EditProfile';
import { GetPot } from './components/GetPot';
import { ShareeHomepage } from './components/ShareeHomepage';
import { ContactUs } from './components/ContactUs';
import { SharerHomepage } from './components/SharerHomepage';
import { LandingPage } from './components/LandingPage';
import { SelectStrains } from './components/SelectStrains';
import { OrderConfirmation } from './components/OrderConfirmation';
import { OrderHistory } from "./components/OrderHistory";
import { SharerOrderPage } from "./components/SharerOrderPage";
import { ShareeOrderPage } from "./components/ShareeOrderPage";
import { ReviewOrder } from "./components/ReviewOrder";
// import { EditStrain } from "./components/EditStrain";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />}/>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgotPassword" element={<ForgotPasswordPage />}/>
      <Route path="/signUp" element={<SignUpPage />} />
      <Route path="/selectUserType" element={<SelectUserTypePage />} />
      <Route path="/uploadPFP" element={<UploadPFPPage />} />
      <Route path="/editProfile" element={<EditProfile />} />
      <Route path="/shareeHome" element={<ShareeHomepage />} />
      <Route path="/getPot" element={<GetPot />} />
      <Route path="/selectStrains" element={<SelectStrains />} />
      <Route path="/orderConfirmation" element={<OrderConfirmation />} />
      <Route path="/sharerHome" element={<SharerHomepage />} />
      <Route path="/manageStrains" element={<ManageStrains />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/orderHistory" element={<OrderHistory />} />
      <Route path="/manageStrains" element={<ManageStrains />} />
      <Route path="/manageDelivery" element={<ManageDelivery />} />
      <Route path="/addStrain" element={<AddStrain />} />
      <Route path="/editStrain" element={<EditStrain />} />
      <Route path="/sharerOrderPage" element={<SharerOrderPage/>} />
      <Route path="/shareeOrderPage" element={<ShareeOrderPage/>} />
      <Route path="/reviewOrder" element={<ReviewOrder />} />
    </Routes>
  );
}

export default App;
