import "./ReviewOrder.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import full from "./rating_full.png";
import empty from "./rating_empty.png";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "./../firebase-config.js";

export const ReviewOrder = () => {

    const [userid, setUID] = useState("");
    const [otherUser, setOtherUser] = useState("");
    const [orderID, setOrderID] = useState("");
    const [rating, setRating] = useState(0);
    const [desc, setDesc] = useState("");
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setOtherUser(state.otherUser);
        setUID(state.uID);
        setOrderID(state.orderID);
    }, []);

    const rate = (event) => {
        let stars = event.target.alt;
        if (stars === rating) {
            setRating(0);
            stars = 0;
        } else {
            setRating(stars);
        }

        let starsDiv = document.getElementById("stars").children;
        for (let i = 0; i < 5; i++) {
            if (i < stars) {
                starsDiv.item(i).src = full;
            } else {
                starsDiv.item(i).src = empty;
            }
        }
    };

    const save = async () => {
        const otherRef = doc(db, "users", otherUser.id);
        let other = (await getDoc(otherRef)).data();
        let ratingsArr = other.ratings;
        ratingsArr[rating] = ratingsArr[rating] + 1;

        let total = {num:0, sum:0};
        ratingsArr.forEach((element, index) => {
            total.num += element;
            total.sum += element * index;
        });
        let newRating = Math.round(total.sum / total.num);
        await updateDoc(otherRef, {
            ratings:ratingsArr,
            rating:newRating
        });

        const orderRef = doc(db, "orders", orderID);
        if (other.sharer) {
            await updateDoc(orderRef, {
                ratingBuyer:`${rating}/5, ${desc}`
            });
        } else {
            await updateDoc(orderRef, {
                ratingSeller:`${rating}/5, ${desc}`
            });
        }
        alert("Your rating has been successfully added to the order.");
        navigate(`/${other.sharer ? "sharee" : "sharer"}home`, {state:{uID:userid}});
    };

    return (
        <div id="phoneFrame">
            <div id="header">
                <div />
                <div />
                <b>Review Order</b>
                <button onClick={async () => {
                    const otherRef = doc(db, "users", otherUser.id);
                    let other = (await getDoc(otherRef)).data();
                    navigate(`/${other.sharer ? "sharee" : "sharer"}home`, {state:{uID:userid}});
                }}>Close</button>
            </div>
            <div id="blurb">How was your experience with this order? Please rate this order with a start rating, where 5 is best, and 1 is worst.</div>
            <div id="rating">
                <b>{otherUser.sharer ? "Sharer" : "Sharee"} Ratings :</b>
                <div id="stars">
                    <img src={empty} id="1_star" onClick={rate} alt={1} width="40vw" height="40vw"/>
                    <img src={empty} id="2_star" onClick={rate} alt={2} width="40vw" height="40vw"/>
                    <img src={empty} id="3_star" onClick={rate} alt={3} width="40vw" height="40vw"/>
                    <img src={empty} id="4_star" onClick={rate} alt={4} width="40vw" height="40vw"/>
                    <img src={empty} id="5_star" onClick={rate} alt={5} width="40vw" height="40vw"/>
                </div>
            </div>
            <div id="review">
                <p>Review or Suggestion</p>
                <textarea onChange={(event) => {setDesc(event.target.value)}} id="reviewText" rows={6} placeholder="Click to type." />
            </div>
            <button id="save" onClick={save} >SAVE RATING</button>
        </div>
    );
};