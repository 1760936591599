import React from 'react';
import { useState, useEffect } from 'react';
import './ShareeOrderPage.css'
import { useLocation, useNavigate } from "react-router-dom";
import { db, storage } from "./../firebase-config.js"
import { updateDoc, doc, getDoc } from "firebase/firestore"
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";

export const ShareeOrderPage = () => {

    const {state} = useLocation();
    const userid = state.uID;
    const sellerID = state.sellerID;
    let orderID = state.orderID;
    let phoneNumber = state.sellerNum;
    const navigate = useNavigate();
    const [called, setCalled] = useState(false);
    const [currentLocation, setCurrentLocation] = useState("");
    const [currentLongitude, setCurrentLongitude] = useState(0);
    const [currentLatitude, setCurrentLatitude] = useState(0);
    const [sellerName, setSellerName] = useState("");
    const [sellerEmail, setSellerEmail] = useState("");
    const [sellerLocation, setSellerLocation] = useState("");
    const [time, setTime] = useState("");
    const [image, setImage] = useState("");
    const [orderPickup, setOrderPickup] = useState("")

    useEffect(() => {
        listAll(ref(storage, `images/${sellerID}/`)).then((response) => {
            let img = response.items.find(item => item.name.split("_")[0] === "pfp");
            getDownloadURL(img).then((url) => {
                setImage(url);
            }).catch(() => {
                console.log("Error downloading image!");
            });
        }).catch(() => {
            console.log("No images to find!");
        });
        getSellerInfo(sellerID);
        getOrderInfo(orderID);
    }, [sellerLocation]);

    useEffect(() => {
        
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

            return () => clearInterval(interval);
        
    }, []);

    async function listener() {
        let order = (await getDoc(doc(db, "orders", orderID))).data();
        if (order.completed) {
            let sellerDoc = (await getDoc(doc(db, "users", sellerID)));
            let seller = sellerDoc.data();
            seller.id = sellerDoc.id;
            navigate("/reviewOrder", {state: {otherUser: seller, uID: userid, orderID: orderID}});
        }
    };

    {listener()}

    async function getSellerInfo(sellerID) {
        const docRef = doc(db, "users", sellerID);
        const docSnap = await getDoc(docRef);
        setSellerName(docSnap.data().name);
        setSellerEmail(docSnap.data().email);
        setSellerLocation(docSnap.data().location);
        
    }
    async function getOrderInfo(orderID) {
        const docRef = doc(db, "orders", orderID);
        const docSnap = await getDoc(docRef);
        setOrderPickup(docSnap.data().pickup)
    }

    const updateUser = async (id, address, lat, long) => {
        const userDoc = doc(db, "users", id);
        const newFields = {location: address, latitude: lat, longitude: long};
        await updateDoc(userDoc, newFields);
    }

    function splitAddress(val) {
        var newAddress = "";
        var pieces = val.split(" ");
        for (var i = 0; i < pieces.length; i++) {
            if (i === 0 || i === pieces.length - 1) {
                newAddress = newAddress + pieces[i];
            } else {
                newAddress = newAddress + "+" + pieces[i];
            }
        }
        return newAddress;
    }

    const successCallback = (position) => {
        setCurrentLongitude(position.coords.longitude);
        setCurrentLatitude(position.coords.latitude);
        if (!called && (currentLatitude !== 0 || currentLongitude !== 0)) {
            geoCoding(currentLatitude, currentLongitude);
        }
    };
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback);
    }

    async function geoCoding(lat, long) {
        setCalled(true);
        const response = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat.toString() + "," + long.toString() + "&key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc");
        const data = await response.json();
        if (data.status === "OK") {
            setCurrentLocation(data.results[0].formatted_address);
            updateUser(userid, data.results[0].formatted_address, lat, long);
        } else {
            setCurrentLocation("your current location");
            updateUser(userid, "current location", lat, long);
        }
    }

    return(<div>

        <div  className="map-responsive20">

        {sellerLocation.length != 0 && <iframe src={"https://www.google.com/maps/embed/v1/directions?key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc&origin=" + splitAddress(currentLocation) + "&destination=" + splitAddress(sellerLocation)}
        width="100vw" height="55vh" style={{border:'0'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>}

        {sellerLocation.length == 0 && <iframe src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc&q=" + splitAddress(sellerLocation)}
        width="100vw" height="65vh" style={{border:'0'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>}

        </div>

        <div id="shareeOrderID">Order ID: {orderID}</div>

        <div id="shareeOrderLocation">You are currently at {currentLocation}</div>

        <div id="shareeOrderInstructions">{orderPickup ? <div>You can now go for the pickup at {sellerLocation}. Make sure your GPS is on.</div> : <div>{sellerName} is on their way to you!</div>}</div>

        <div id="shareeInfo">
            
            <img src={image} id="sharerpfp" alt={"sharer_image"}/>
            
            <div id="sharee_col">
            <div id="sharerOrderName">Name: {sellerName}</div>

            <div id="sharerOrderEmail">Email: {sellerEmail}</div>

            <div id="sharerOrderNumber">Phone Number: {phoneNumber}</div>
            </div>
        </div>

        {/* <div id="shareeSellerLocation">Seller Location: {sellerLocation}</div> */}

    </div>);

}