import React, { useState, useMemo, useEffect } from 'react';
import './GetPot.css';
import { DistanceMatrixService, GoogleMap, LoadScript } from '@react-google-maps/api'
import { db } from "./../firebase-config.js"
import { getDoc, doc} from "firebase/firestore"

export const SharerButton = ({sharer, index, selectSharer, userid, src}) => {

    const [distance, setDistance] = useState("");
    const [time, setTime] = useState("");
    const [location, setLocation] = useState("");
    const destination = sharer.data().location;
    // const [apiLoaded, setApiLoaded] = useState(false);

    async function findLocation() {
        const docRef = doc(db, "users", userid);
        try { 
            const docSnap = await getDoc(docRef);
            setLocation(docSnap.data().location);
            return docSnap.data().location;
        } catch(error) { 
            console.log(error);
        }
    }

    const callback = ((response, status) => {
        findLocation();
        if (status === 'OK') {
            try {
                setDistance(response.rows[0].elements[0].distance.text);
                setTime(response.rows[0].elements[0].duration_in_traffic.text);
            } catch(error) {
                setDistance("distance not found");
                setTime("time not found");
            }
        } else {
            setDistance("distance not found");
            setTime("time not found");
        }
    });

    const memoComponent = useMemo(() =>
        <LoadScript googleMapsApiKey="AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc">
            <GoogleMap>
            <DistanceMatrixService
                options={{
                    destinations: [destination],
                    origins: [location],
                    travelMode: 'DRIVING',
                    drivingOptions: {
                    departureTime: new Date(Date.now()),
                    }
                }}
                callback={callback}/>
            </GoogleMap>
        </LoadScript>
    , [location.length]);

    return <>
    <button key={index} alt={index} className='sharerButton' onClick={(e) => selectSharer(e, distance, time)}>
        <div className='imgInfoDiv'>
            <img className='pfp_gp' src={src} width="100"  height="100" alt={"Sharer #" + (index + 1)}/>
            <div className='infoDiv'>
                <p><b>Name : </b>{sharer.data().name}</p>
                <p><b>Distance : </b>{distance}</p>
                <p><b>Delivery Type : </b>{sharer.data().delivery}</p>
                <p><b>ETA : </b>{time}</p>
            </div>
        </div>
        <p className='addr'><b>Address : </b>{sharer.data().location}</p>
    </button>
    <div>
        {memoComponent}
    </div>
    </>
}