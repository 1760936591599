import full from "./rating_full.png";
import empty from "./rating_empty.png";
import "./SelectStrains.css";
import { useState, useReducer, useEffect, useMemo } from "react";
import { storage } from "../firebase-config.js";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import logo from "./Green-2.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import { DistanceMatrixService, GoogleMap, LoadScript } from '@react-google-maps/api'
import { db } from "./../firebase-config.js"
import { getDoc, doc} from "firebase/firestore"

export const SelectStrains = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const sharer = location.state?.sharer;
    const strains = location.state?.strains;
    const distance = location.state?.distance;
    const time = location.state?.time;

    const [cart, updateCart] = useState([]);
    const reducer = (state, action) => {
        return state.some((item) => item.id === action.payload.id) ? state : [...state, action.payload];
    };
    const [strainPics, dispatch] = useReducer(reducer, []);
 
    const close = () => {
        navigate("/getPot", {state: {uID: location.state.uID}});
    };

    let rate = [];
    for (var i = 1; i < 6; i++) {
        if (i <= sharer?.rating) {
            rate.push(true);
        } else {
            rate.push(false);
        }
    }

    const choose = (event) => {
        let identifier = event.currentTarget.id.split(",");
        let strain = strains.find(strain => strains.indexOf(strain) === parseInt(identifier[0]));
        
        if (identifier[1] === "add") {
            add(strain, event.currentTarget.id, identifier[0]);
        } else {
            remove(strain, event.currentTarget.id, identifier[0]);
        }
    };

    const add = (strain, btnID, strIndex) => {
        updateCart([...cart, strain]);

        // switches button functionality
        let button = document.getElementById(btnID);
        button.setAttribute("class", "remove");
        button.setAttribute("id", strIndex + ",remove");
        button.textContent = "REMOVE";
    };

    const remove = (strain, btnID, strIndex) => {
        let ind = cart.indexOf(strain);
        let newCart = cart.slice(0, ind).concat(cart.slice(ind + 1, cart.length));
        updateCart(newCart);

        // switches button functionality
        let button = document.getElementById(btnID);
        button.setAttribute("class", "add");
        button.setAttribute("id", strIndex + ",add");
        button.textContent = "ADD";
    }

    const proceed = () => {
        navigate("/orderConfirmation", {state:{cart:cart, sharer:sharer, strains:strains, uID:location.state.uID, time: time}});
    };

    useEffect(() => {
        if (!location.state) {
            navigate("/getPot", {state: {uID: location.state.uID}});
        }
        async function fetchStrains() {
            strains?.forEach((strain) => {
                listAll(ref(storage, `images/${sharer.id}/strains/`)).then((response) => {
                    let img = response.items.find(item => item.name.split("_")[0] === strain.id);
                    if (img) {
                        getDownloadURL(img).then((url) => {
                            dispatch({payload:{id:strain.id, src:url}});
                        });
                    } else {
                        dispatch({payload:{id:strain.id, src:logo}});
                    }
                });
            });
        }
        fetchStrains();
    }, []);

    return (
        <div id="phoneFrame">
            <div id="headerStrain">
                <div/>
                <div/>
                <div/>
                <b>Select Strain</b>
                <button onClick={close}>Close</button>
            </div>
            <div className="imgInfo">
                <img src={sharer?.src} id="pfp_ss" width="75vw" height="75vw" alt="pfp"/>
                <div id="infoDiv">
                    <p><b>Name : </b>{sharer?.name}</p>
                    <p><b>Email : </b>{sharer?.email}</p>
                    <p><b>Age : </b>{sharer?.age}</p>
                </div>
            </div>
            <div id="addrInfo">
                <b>Address :</b>
                <p>{sharer?.location}</p>
            </div>
            <div id="distInfo">
                <b>Distance Away :</b>
                <p>{distance} Km away from this seller.</p> {/* fix this */}
            </div>
            <div id="rating">
                <b>Sharer Ratings :</b>
                <div>
                    {rate.map((star, index) => {
                        let source = star ? full : empty;
                        return <img src={source} width="40vw" height="40vw" key={index} alt={"star #" + (index + 1)} />
                    })}
                </div>
            </div>
            <>
                {strains?.map((strain, index) => {
                    const item = strainPics.find(item => item.id === strain.id);
                    const src = item?.src;
                    return <div className="strainDiv" key={index} >
                        <div className="imgInfo">
                            <img id="strainPic_ss" src={src} width="90vw" height="90vw" alt={"Strain #" + (index + 1)} />
                            <div className="infoDiv">
                                <p><b>Name : </b>{strain.name}</p>
                                <p><b>Strain : </b>{strain.type}</p>
                                <p><b>Weight : </b>{strain.weight}</p>
                            </div>
                        </div>
                        <div className="price">
                            <p><b>Price : </b>{"$ " + strain.price.toFixed(2)}</p>
                            <button className="add" id={index + ",add"} onClick={choose}>ADD</button>
                        </div>
                    </div>
                })}
            </>
            <>
                {cart.length !== 0 && <div id="nextDiv">
                    <div id="total">
                        <p>{cart.length} item(s) added</p>
                        <b>{cart.reduce(function(prev, current) {
                            return prev + +current.price
                        }, 0).toFixed(2)}</b>
                    </div>
                    <button onClick={proceed}>PROCEED</button>
                </div>}
            </>
        </div>
    );
}