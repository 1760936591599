import React, { useEffect, useReducer, useState } from 'react';
import './GetPot.css';
import backArrow from "./Arrow_left.png";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db, storage } from "./../firebase-config.js";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import logo from "./Green-2.jpeg";
import { SharerButton } from './SharerButton'; // add this back!!
import { useNavigate, useLocation } from "react-router-dom";

export const GetPot = () => {

    const { state } = useLocation();
    const user_id = state.uID;
    const user_ref = doc(db, "users", user_id);
    const [sharerQuery, setSharers] = useState(null);
    const navigate = useNavigate();
    // const [distance, setDistance] = useState(0);
    // console.log(distance)
    // prevents duplicates from being added
    const reducer = (state, action) => {
        return state.some((item) => item.id === action.payload.id) ? state : [...state, action.payload];
    };
    const [pfpList, dispatch] = useReducer(reducer, []);

    async function selectSharer (event, distance, time) {
        let selected = sharerQuery.find(sharer => sharerQuery.indexOf(sharer) === parseInt(event.currentTarget.getAttribute("alt")));
        let strainList = [];
        let strains = await getDocs(query(collection(db, ("users/" + selected.id + "/strains")), where("active", "==", true)));
        strains.forEach((doc) => {
            let strain = doc.data();
            strain.id = doc.id;
            strainList.push(strain);
        });

        let sharer = selected.data();
        sharer.id = selected.id;
        sharer.src = pfpList.find(item => item.id === sharer.id).src;
        navigate("/selectStrains", {state:{sharer:sharer, strains:strainList, uID:user_id, distance:distance, time: time}});
    };

    useEffect(() => {
        async function generateSharers() {
            const user = await getDoc(user_ref);
            const q = query(collection(db, "users"), where("sharer", "==", true), where("state", "==", user.data().state), where("active", "==", true));
            const querySnapshot = await getDocs(q);
            let finalSharers = [];
            querySnapshot.docs.forEach((user) => {
                if (user.data().activeStrains > 0) {
                    listAll(ref(storage, `images/${user.id}/`)).then((response) => {
                        let img = response.items.find(item => item.name.split("_")[0] === "pfp");
                        if (img) {
                            getDownloadURL(img).then((url) => {
                                dispatch({payload:{id:user.id, src:url}});
                            });
                        } else {
                            dispatch({payload:{id:user.id, src:logo}});
                        }
                    });
                    finalSharers.push(user);
                }
            });
            setSharers(finalSharers);
        }
        generateSharers();
    }, []);

    const home = () => {
        navigate("/shareeHome", {state: {uID: state.uID}});
    };

    return(
        <div id='phoneFrame'>

            <div id='headerGP'>
                <button id='backButtonGP' onClick={home}>
                    <img id="backPic_GP" src={backArrow} alt="Back"/>
                </button>
                <p id='pageTitleGP'>Get Pot</p>
            </div>

            <>
                {sharerQuery && sharerQuery.map((sharer, index) => {
                    const item = pfpList.find(item => item.id === sharer.id);
                    const src = item?.src;
                    return <SharerButton key={index} sharer={sharer} index={index} selectSharer={selectSharer} userid={user_id} src={src}/>
                })}
            </>
        </div>
    );
}