import React from 'react';
import { useState, useEffect } from 'react';
import './SharerOrderPage.css'
import { useLocation, useNavigate } from "react-router-dom";
import { db, storage } from "./../firebase-config.js"
import { updateDoc, doc, getDoc } from "firebase/firestore"
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";


export const SharerOrderPage = () => {

    const {state} = useLocation();
    const navigate = useNavigate();
    const userid = state.uID;
    const [order, setOrder] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [currentLongitude, setCurrentLongitude] = useState(null);
    const [currentLatitude, setCurrentLatitude] = useState(null);
    const [called, setCalled] = useState(false);
    const [buyer, setBuyer] = useState("");
    const [buyerID, setBuyerID] = useState(state.buyer);
    const [seller, setSeller] = useState(null);
    const [image, setImage] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    
    async function fetchImage() {
        try {
        //   console.log(buyer.id)
          const response = await listAll(ref(storage, `images/${buyerID}/`));
          const img = response.items.find(item => item.name.split("_")[0] === "pfp");
          if(img){
            const url = await getDownloadURL(img);
            setImage(url);
            setImageLoaded(true); 
          }
        } catch (error) {
          console.log("Error fetching image:", error);
        }
        return (null);
      };

    useEffect(() => {
        // setUID(state.uID);
        const getUsers = async () => {
            let ord = (await getDoc(doc(db, "orders", state.order))).data();
            ord.id = state.order;
            setOrder(ord);
            let buyer = (await getDoc(doc(db, "users", ord.buyer))).data();
            buyer.id = ord.buyer;
            setBuyer(buyer);
            let seller = (await getDoc(doc(db, "users", ord.seller))).data();
            seller.id = ord.seller;
            setSeller(seller);
        };
        console.log(buyer.id)
        getUsers();
        // setTimeout(fetchImage, 5000);  
        fetchImage();
        console.log(imageLoaded);
    }, []);   
    
    const updateOrder = async (order) => {
        try {
            const userDoc = doc(db, "orders", order.id);
            const newFields = {completed: true};
            await updateDoc(userDoc, newFields);
        } catch (e) {
            console.log(e);
        }
    }

    const updateUser = async (id, address, lat, long) => {
        const userDoc = doc(db, "users", id);
        const newFields = {location: address, latitude: lat, longitude: long};
        await updateDoc(userDoc, newFields);
    }

    function splitAddress(val) {
        var newAddress = "";
        var pieces = val.split(" ");
        for (var i = 0; i < pieces.length; i++) {
            if (i === 0 || i === pieces.length - 1) {
                newAddress = newAddress + pieces[i];
            } else {
                newAddress = newAddress + "+" + pieces[i];
            }
        }
        return newAddress;
    }

    async function markDelivered() {
        let response = window.confirm("Are you sure you want to mark the order as delivered?");
        if (response) {
            updateOrder(order);
            navigate("/reviewOrder", { state: {uID: userid, otherUser: buyer, orderID: order.id} });
        }
        console.log(buyer.id)
    }

    const successCallback = (position) => {
        setCurrentLongitude(position.coords.longitude);
        setCurrentLatitude(position.coords.latitude);
        if (!called && (currentLatitude !== 0 || currentLongitude !== 0)) {
            geoCoding(position.coords.latitude, position.coords.longitude);
        }
    };


    if (navigator.geolocation && currentLocation) {
        navigator.geolocation.getCurrentPosition(successCallback);
    }

    async function geoCoding(lat, long) {
        setCalled(true);
        const response = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat.toString() + "," + long.toString() + "&key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc");
        const data = await response.json();
        if (data.status === "OK") {
            setCurrentLocation(data.results[0].formatted_address);
            await updateUser(userid, data.results[0].formatted_address, lat, long);
        } else {
            setCurrentLocation("your current location");
            await updateUser(userid, "current location", lat, long);
        }
    }


    return(<div>

        <div  className="map-responsive10">

        {buyer && seller && <iframe src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc&origin=${splitAddress(buyer.location)}&destination=${splitAddress(seller.location)}`}
        width="100vw" height="55vh" style={{border:'0'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>}

        </div>

        <div id="orderID">Order ID: {order?.id}</div>

        <div id="sharerOrderLocation">You are currently selling at {seller?.location}</div>

        <div id="sharerOrderInstructions">{order?.pickup ? <div>{buyer?.name} is coming to get pot from you</div> : <div>You are delivering pot to {buyer?.name}</div>}</div>

        <div id="sharerInfo">
            <div> 
                {imageLoaded ? (<img src={image} id="shareepfp" alt={"sharer_image"}/>): null}
            </div>
            
            
            <div id="sharer_col">
            <div id="sharerOrderName">Name: {buyer?.name}</div>

            <div id="sharerOrderEmail">Email: {buyer?.email}</div>

            <div id="sharerOrderNumber">Phone Number: {order?.buyerPhone}</div>
            </div>
        </div>

        <div><button id="markDelivered" onClick={markDelivered}>Mark As Delivered</button></div>
        
    </div>);

}