import './ManageStrains.css'
import logo from "./Green-2.jpeg"
import backArrow from "./Arrow_left.png"
import edit from "./blackcircle.png"
import inactive from "./inactive.png"
import active from "./active.png"
import plus from "./black_plus.png"
import { db, storage } from "./../firebase-config.js"
import {collection, getDocs, updateDoc, deleteDoc, doc, getDoc} from "firebase/firestore"
import React, { useEffect, useReducer, useRef, useState } from "react";
import { ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import Popup from "reactjs-popup";
import { useNavigate, useLocation } from "react-router-dom";

export const ManageStrains = () => { 

    const { state } = useLocation();
    const user_id = state.uID;
    const userDoc = doc(db, "users", user_id);
    const strainsCollectionsRef = collection(db, `users/${user_id}/strains`);

    const tooltipRef = useRef();
    const openTooltip = () => tooltipRef.current.open();
    const closeTooltip = () => tooltipRef.current.close();
    const [action, setAction] = useState("");

    const navigate = useNavigate();

    // prevents duplicates from being added
    const reducer = (state, action) => {
        switch (action.type) {
            case "add":
                return state.some((item) => item.id === action.payload.id) ? state : [...state, action.payload];
            case "remove":
                return state.filter((item) => item.id !== action.payload.id);
            default:
                return state; 
        }
    };
    const [strains, dispatch] = useReducer(reducer, []);

    //Read from Firestore specifically users
    useEffect(() => {
        const getStrains = async () => {
            const data = await getDocs(strainsCollectionsRef);
            data.docs.forEach((strain) => {
                listAll(ref(storage, `images/${user_id}/strains/`)).then((response) => {
                    let img = response.items.find(item => item.name.split("_")[0] === strain.id);
                    if (img) {
                        getDownloadURL(img).then((url) => {
                            let strObject = strain.data();
                            strObject.id = strain.id;
                            strObject.src = url;
                            dispatch({payload:strObject, type:"add"});
                        });
                    } else {
                        let strObject = strain.data();
                        strObject.id = strain.id;
                        strObject.src = logo;
                        dispatch({payload:strObject, type:"add"});
                    }
                });
            });
        };
        getStrains();
    }, []);

    const deleteStrain = async (id) => {
        setAction("deleted");
        if (!window.confirm("Do you want to delete this strain?")) {
            return;
        }

        // delete img from storage
        let strainID = id.substring(14);
        const data = await getDocs(strainsCollectionsRef);
        let str = data.docs.find(strain => strain.id === strainID);
        listAll(ref(storage, `images/${user_id}/strains/`)).then((response) => {
            let imgRef = response.items.find(item => item.name.split("_")[0] === str.id);
            if (imgRef) {
                deleteObject(imgRef).catch((reason) => {
                    alert("Failed to delete the strain.");
                    return;
                });
            }
        });

        // delete from db
        const strainDoc = doc(db, `users/${user_id}/strains`, strainID);
        await deleteDoc(strainDoc).catch((reason) => {
            alert("Failed to delete the strain.");
            return;
        });

        // delete from page
        let strain = strains.find(item => item.id === strainID);
        dispatch({payload:strain, type:"remove"});

        // update activeStrains count
        let user = await getDoc(userDoc);
        await updateDoc(userDoc, {activeStrains: user.data().activeStrains - 1});

        openTooltip();
    }

    const updateStrain = async (id) => {
        let strain = {id:id.substring(14)};
        let icon = document.getElementById(`active_${strain.id}`);
        strain.active = icon.className.substring(4,5) === "2";

        setAction("status updated");
        if (!window.confirm(`Do you want to ${strain.active ? "de" : ""}activate this strain?`)) {
            return;
        }

        // update activeStrains count
        let user = await getDoc(userDoc);
        let newCount = strain.active ? user.data().activeStrains - 1 : user.data().activeStrains + 1;
        await updateDoc(userDoc, {activeStrains: newCount});
        strains.find(item => item.id === strain.id).active = !strain.active;

        const strainDoc = doc(db, `users/${user_id}/strains`, strain.id);
        const newFields = {active: strain.active ? false : true};

        icon.className = strain.active ? "icon3_ms": "icon2_ms";
        icon.setAttribute("src", strain.active ? inactive: active);
        icon.setAttribute("height", strain.active ? "16" : "32");
        icon.setAttribute("width", strain.active ? "16" : "32");

        await updateDoc(strainDoc, newFields);
        openTooltip();
    }

    const editStrain = (id) => {
        let strainID = id.substring(12);
        if (!window.confirm("Do you want to edit this strain?")) {
            return;
        }

        let strain = strains.find(item => item.id === strainID);
        console.log(strain);
        navigate("/editStrain", {state: {uID: state.uID, strain: strain}})
    };

    const backBtn = () => {
        navigate("/sharerHome", {state: {uID: state.uID}});
    }
    const add = () => {
        navigate("/addStrain", {state: {uID: state.uID}});
    };

    return (
        <div id="phoneFrame">
            <div id='headerMS'>
                <button id='backButtonMS' onClick={backBtn}>
                    <img id="backPic_MS" src={backArrow} alt="Back"/>
                </button>
                <p id='pageTitleMS'>Manage Strains</p>
            </div>

            <div>
                {strains.map((strain) => {
                    return (
                        <div key={strain.id} id={strain.id} className = "mode_ms"> 
                            <div id = "row1_ms">
                                <div id = "col1_ms">
                                    <img src = {strain.src} id="img_strain" alt = "Strain 2"/>
                                </div>
                                <div id= "col2_ms">
                                    <div id="word1_ms">Name : {strain.name}</div>
                                    <div id="word1_ms">Strain : {strain.type}</div>
                                    <div id="word1_ms">Weight : {strain.weight}</div>
                                    <div id="word1_ms">Price : {"$" + strain.price.toFixed(2)}</div>
                                </div>
                                <div id= "col3_ms">
                                    <img src = {strain.active ? active : inactive}
                                         id={"active_" + strain.id}
                                         className = {strain.active ? "icon2_ms": "icon3_ms"} 
                                         width = {strain.active ? "32" : "16"}
                                         height = {strain.active ? "32" : "16"} />
                                    <Popup
                                        id="popup"
                                        trigger={<img src = {edit} id = "icon1_ms" width = "30" height = "30" ></img>}
                                        position="bottom right"
                                        on="click"
                                        closeOnDocumentClick
                                        arrow={false}
                                    >
                                        <div className='menu'>
                                            <div id={"button_edit_" + strain.id} onClick={(event) => {editStrain(event.target.id)}}>Edit</div>
                                            <div id={"button_delete_" + strain.id} onClick={(event) => {deleteStrain(event.target.id)}}>Delete</div>
                                            <div id={"button_active_" + strain.id} onClick={(event) => {updateStrain(event.target.id)}}>Toggle On/Off</div>
                                        </div>
                                    </Popup>
                                </div>
                            </div>
                            <div id = "row2_ms">
                                <div id = "word2_ms"> Description : {strain.description}</div>  
                            </div>
                            
                        </div>
                    );
                })}
            </div>
            {/* Plus Button */}
            <div id="plus_ms">
                <input id="plus_button_ms" type="image" src={plus} alt="Default" onClick={add}/>
            </div>
            <Popup
                contentStyle={{borderRadius: "10px", padding: "2%"}}
                ref={tooltipRef}
                trigger={<div id="statusPopup"></div>}
                arrow={false}
                position="top center"
                modal={false}
                closeOnDocumentClick={false}
                onOpen={() => {
                    const timer = setTimeout(() => {
                        closeTooltip();
                    }, 3500);
                    return () => clearTimeout(timer);
                }}
            >
                <div>Strain {action} successfully</div>
            </Popup>
        </div>

        );
    }
    