import React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import './SharerHomepage.css'
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "./../firebase-config.js"
import {collection, updateDoc, doc, query, where, getDocs, getDoc, deleteDoc} from "firebase/firestore"
import NavbarSharer from './NavbarSharer';
import bluebell from "./bluebell.png";
import Popup from 'reactjs-popup';
import PayPalSharer from "./PayPalSharer";
import { DistanceMatrixService, GoogleMap, LoadScript } from '@react-google-maps/api'


export const SharerHomepage = () => {

    const [currentLocation, setCurrentLocation] = useState("");
    const [currentLongitude, setCurrentLongitude] = useState(0);
    const [currentLatitude, setCurrentLatitude] = useState(0);
    const [useCoords, setUseCoords] = useState(1);
    const [online, setOnline] = useState(false);
    const [called, setCalled] = useState(false);
    const [currentOrder, setCurrentOrder] = useState("")
    const navigate = useNavigate();
    const [time, setTime] = useState("");
    const popRef = useRef();
    const openPayPal = (order) => {
        popRef.current.open();
        popRef.order = order;
        setCurrentOrder(order)
    };
    const closePayPal = (processed) => {
        popRef.current.close();
        popRef.processed = processed;
        updateOrder(currentOrder); 
    };
    const { state } = useLocation();
    const [userid, setUID] = useState("");
    const [notif, setNotif] = useState(null)
    const [norder, setNorder] = useState(true);

    useEffect(() => {
        if (notif == null){
            Notification.requestPermission().then(perm =>{
                setNotif(perm)
            })
        }
        setUID(state.uID);
        const getActive = async () => {
            const userRef = doc(db, "users", state.uID);
            let user = (await getDoc(userRef)).data();
            setOnline(user.active);
        };
        getActive();
    }, []);

    const [ordersList, setOrdersList] = useState([]);
    const ordersRef = collection(db, "orders");
    const [currentBuyer, setCurrentBuyer] = useState("");
    const [currentStrains, setCurrentStrains] = useState("");
    const [buyerID, setBuyerID] = useState("");

    var beginningDate = Date.now() - 120000;
    var beginningDateObject = new Date(beginningDate);
    var now = Date.now();
    
    
    const getOrders = async () => {
        //Read Orders
        try {
            const q = query(ordersRef, where("createdAt", ">=", beginningDateObject), where("seller", "==", userid));
            const data = await getDocs(q);
            const filteredData = data.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id
            }));
            setOrdersList(filteredData);
          } catch (err) {
            console.error(err);
          }
    }
    const getBuyer = async (e) => {
        //Read Orders
        try {
            const docRef = doc(db, "users", e);
            const buy = await getDoc(docRef);
            const buyer = buy.data();
            buyer.id = buy.id;
            setCurrentBuyer(buyer);
        } catch (err) {
            console.error(err);
        }
        
    }

    const updateUser = async (id, address, lat, long) => {
        const userDoc = doc(db, "users", id);
        const newFields = {location: address, latitude: lat, longitude: long};
        await updateDoc(userDoc, newFields);
    }

    function alertPopUp() {
        let location = prompt("PotExpress\nWrite down your location");
        if (location != null && location != "") {
            setCurrentLocation(location);
            setUseCoords(0);
            findLatLong(location);
            updateUser(userid, location, currentLatitude, currentLongitude);
        }
    }

    async function payPopUp(orderid) {
        let numb =  prompt("Please write your contact number so that sharee can contact you when reached. After that pay $1.00 as a connecting fee and you are good to go.");
        if (numb != null && numb.match(/^\d{10}$/)){
            let o = doc(db, "orders", orderid);
            await updateDoc(o, {
                sellerPhone: numb
            });
            //OPEN PAYPAL
            // openPayPal(orderid);
            updateOrder(orderid); 
        } else {
            alert("You need to input a valid phone number.");
        }
        
    }

    async function onlinePopUp() {
        if (currentLocation.length == 0) {
            alert("You need to input your location before you go online");
        } else {
            let response = window.confirm("Are you sure you would like to go online? You will be shown to buyers until you go offline");
            if (response && currentLocation.length != 0) {
                const userRef = doc(db, "users", userid);
                await updateDoc(userRef, {active: true});
                setOnline(true);
            }
        }
    }

    async function offlineClicked() {
        let response = window.confirm("Are you sure you would like to go offline?");
        if (response) {
            const userRef = doc(db, "users", userid);
            await updateDoc(userRef, {active: false});
            setOnline(false);
        }
    }

    function splitAddress(val) {
        var newAddress = "";
        var pieces = val.split(" ");
        for (var i = 0; i < pieces.length; i++) {
            if (i === 0 || i === pieces.length - 1) {
                newAddress = newAddress + pieces[i];
            } else {
                newAddress = newAddress + "+" + pieces[i];
            }
        }
        return newAddress;
    }

    const successCallback = (position) => {
        setCurrentLongitude(position.coords.longitude);
        setCurrentLatitude(position.coords.latitude);
        if (!called && (currentLatitude !== 0 || currentLongitude !== 0)) {
            geoCoding(currentLatitude, currentLongitude);
        }
    };
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback);
    }

    async function geoCoding(lat, long) {
        setCalled(true);
        const response = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat.toString() + "," + long.toString() + "&key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc");
        const data = await response.json();
        if (data.status === "OK") {
            setCurrentLocation(data.results[0].formatted_address);
            updateUser(userid, data.results[0].formatted_address, lat, long);
        } else {
            setCurrentLocation("your current location");
            updateUser(userid, "current location", lat, long);
        }
    }

    async function findLatLong(address) {
        var lat;
        var lon;
        const response = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc");
        const data = await response.json();
        if (data.status === "OK") {
            lat = (data.results[0].geometry.location.lat);
            lon = (data.results[0].geometry.location.lng);
        } else {
            setCurrentLatitude(null);
            setCurrentLongitude(null);
            return 0;
        }
        setCurrentLatitude(lat);
        setCurrentLongitude(lon);
        return 1;
    }

    const updateOrder = async (order) => {
        try {
            const userDoc = doc(db, "orders", order)
            const newFields = {accepted: true}
            await updateDoc(userDoc, newFields)
            navigate("/sharerOrderPage", { state: {uID: userid, order: order, buyer: buyerID} });
        } catch {
            console.log("ERROR");
        }
    }
    const deleteOrder = async (order) => {
        await updateDoc(doc(db, "users", order.buyer), {paid: true});
        await deleteDoc(doc(db, "orders", order.id));
        setNorder(true);
    }
    // console.log(norder)
    function Request() {
        getOrders();
        return (<>
            <main>
                {ordersList && ordersList.map(ord => (!ord.accepted && ord.seller == userid) ? (<Order key={ord.id} id={ord.id} order={ord}/>): null)}
            </main>
            </>)
    }

    function Order(props) {
        const { seller, buyer, strains, createdAt, completed, strainCount } = props.order; 
        getBuyer(buyer);
        setBuyerID(buyer);
        const date = new Date(createdAt.seconds*1000);
        const elapsed = Math.trunc((120000 - (now - date))/1000);
        if (norder) {
            console.log("notification?")
            new Notification("New Order!")
            setNorder(false);
        } 
        if (!completed) {
            return (<>
                <div>{/*message sent or message recieved, style accordingly*/ }
                    <div id = "request_ms">
                        <p><b>One {props.order.pickup ? "Pickup" : "Delivery"} Request</b></p>
                        <img src={bluebell} height="32" width="32"></img>
                        <div id="reqdesc">One person just requested your pot for {props.order.pickup ? "PICKUP" : "DELIVERY"}. You have only 120 sec(s) to accept it. Otherwse it will automatically be rejected.</div>
                        <div id= "sharer">
                            <img src={bluebell} height="64" width="64"></img> 
                            {/* TODO get image from buyer */}
                            <div id="shinfo">
                                <p><b>Name : </b>{currentBuyer.name}</p>
                                <p><b>Email : </b>{currentBuyer.email}</p>
                                <p><b>Strain : </b>{strainCount} Strain(s)</p>  
                            </div>
                        </div>
                        <div id='orderinfo'>
                            <p><b>Date and Time: </b></p>
                            <p>{date.toDateString()} {date.toTimeString()}</p>
                            <p><b>Address: </b></p>
                            <p>{currentBuyer.location}</p>
                            <p><b>Estimate Arrival Time: </b></p>
                            <p>{props.order.eta}</p>
                            <p><b>Transport Type: </b></p>
                            <p>{props.order.type}</p>
                            <p id='countdown'>{elapsed } sec(s) remaining to accept this request</p>
                            <div id="bottom_sh">
                                <button id="reqButton" onClick={(e) => deleteOrder(props.order)}>Reject Request</button>
                                <button id="reqButton" onClick={(e) => payPopUp(props.order.id)}>Accept Request</button>
                            </div>
                        </div>
                    </div>
                </div>
                </>)
        }
    }
    // function ViewStrains() {
    //     currentOrder
    // }

    return(
        <div>

        <div  className="map-responsive2">
        {currentLocation.length == 0 && <iframe src={"https://www.google.com/maps/embed/v1/view?key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc&center=31.000000,-100.000000&zoom=3"}
        width="100vw" height="65vh" style={{border:'0'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>}

            {currentLocation.length != 0 && useCoords == 1 && <iframe src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc&q=" + currentLatitude.toString() + ", " + currentLongitude.toString()}
        width="100vw" height="65vh" style={{border:'0'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>}

            {currentLocation.length != 0 && useCoords == 0 && <iframe src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc&q=" + splitAddress(currentLocation)}
        width="100vw" height="65vh" style={{border:'0'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>}
        </div>

            {currentLocation.length != 0 && <div id="currentLocationInfo">You are currently selling at {currentLocation}</div>}
            {currentLocation.length == 0 && <div id="locationPrompt">Please input your location</div>}
            {}

            {!online && <div>
            <div> <button onClick={alertPopUp} id="changeLocation">Change Location</button> </div>
            <div> <button onClick={onlinePopUp} id="onlineButton">Go Online to Sell</button> </div>
            </div>}

            {online && <div>
                <div id="findingSharee">We are finding the nearest sharee...</div>
                <div><button id="offlineButton" onClick={offlineClicked}>Go Offline</button></div>

            <main>
            <Request/>
            </main>

        </div>}

            {/* <div>
            <Popup
                trigger={<div/>}
                ref={popRef}
                closeOnDocumentClick
                modal
                onClose={() => {
                    if (popRef.processed) {
                        updateOrder(popRef.order);
                    }
                }}
            >
                <PayPalSharer closePayPal={closePayPal}/>
            </Popup>
            </div> */}

            <div id="sharerNavBar">
                <NavbarSharer userid={userid}/>
            </div>
        </div>
    );
}