import React, { useEffect, useReducer } from 'react';
import logo from "./Green-2.jpeg";
import backArrow from "./Arrow_left.png";
import './OrderHistory.css'
import { useLocation, useNavigate } from "react-router-dom";
import { getDoc, doc, query, collection, where, getDocs } from "firebase/firestore";
import { db, storage } from "./../firebase-config.js";
import { getDownloadURL, listAll, ref } from '@firebase/storage';

export const OrderHistory = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const uID = state.uID;

    // prevents duplicates from being added
    const reducer = (state, action) => {
        switch (action.type) {
            case "add":
                return state.some((item) => item.id === action.payload.id) ? state : [...state, action.payload];
            case "remove":
                return state.filter((item) => item.id !== action.payload.id);
            case "sort":
                const {type, order} = action.payload;
                const sorted = [...state].sort((a, b) => {
                    if (order === "asc") {
                        return a[type] > b[type] ? 1 : -1;
                    } else {
                        return a[type] < b[type] ? 1 : -1;
                    }
                });
                return sorted;
            default:
                return state;
        }
    };
    const [history, dispatch] = useReducer(reducer, []);

    async function home() {
        const docRef = doc(db, "users", uID);
        try { 
            const docSnap = await getDoc(docRef);
            if (docSnap.data().sharer) {
                navigate("/sharerHome", { state: {uID: uID} });
            } else if (!docSnap.data().sharer) {
                navigate("/shareeHome", { state: {uID: uID} });
            }
        } catch(error) { 
            console.log(error);
            navigate("/");
        }
    }

    useEffect(() => {
        const fetchOrders = async () => {
            let qSell = query(collection(db, "orders"), where("seller", "==", uID));
            let qBuy = query(collection(db, "orders"), where("buyer", "==", uID));
            let sellHistory = await getDocs(qSell);
            let buyHistory = await getDocs(qBuy);
            let all = [...sellHistory.docs, ...buyHistory.docs];
            all.forEach(async (order) => {
                const strains = await getDocs(collection(db, `orders/${order.id}/strains`));
                listAll(ref(storage, `images/orders/${order.id}/`)).then((response) => {
                    let img = response.items.find(item => item.name.split("_")[0] === (uID === order.data().seller ? "buyer" : "seller"));
                    if (img) {
                        getDownloadURL(img).then((url) => {
                            let o = order.data()
                            o.id = order.id;
                            o.src = url;
                            o.date = new Date(o.createdAt.seconds*1000);
                            o.strains = strains.docs;
                            dispatch({payload:o, type:"add"});
                        });
                    } else {
                        let o = order.data()
                        o.id = order.id;
                        o.src = logo;
                        o.date = new Date(o.createdAt.seconds*1000);
                        o.strains = strains.docs;
                        dispatch({payload:o, type:"add"});
                    }
                    dispatch({payload:{type:"date", order:"asc"}, type:"sort"});
                });
            });
        };
        fetchOrders();
        dispatch({payload:{type:"date", order:"asc"}, type:"sort"});
    }, []);

    return (
        <div id="phoneFrame">
            <div id='headerOH'>
                <button id='backButtonOH' onClick={home}>
                    <img id="backPic_OH" src={backArrow} alt="Back"/>
                </button>
                <p id='pageTitleOH'>Order History</p>
            </div>

            <>
            {
                history.map((order, index) => {
                    return <div key={order.id} id="mode">
                        <img src={order.src} id="img_OH" alt={"order #" + index + 1} />
                        <div id="col1">
                            <div className='infoGroup'>
                                <div id="question2">Ordered On :</div>
                                <div id="answer2">{order.date.toLocaleString()}</div>
                            </div>
                            <div className='infoGroup'>
                                <div id="question2">Total Price :</div>
                                <div id="answer2">{"$ " + order.total?.toFixed(2)}</div>
                            </div>
                        </div>
                        <div id="col1">
                            {
                                order.strains.map((strainDoc) => {
                                    let strain = strainDoc.data();
                                    return <div key={strain.id} className="strainGroup">
                                        <div id="question2">Name : <b>{strain.name}</b></div>
                                        <div id="question2">Strain : <b>{strain.type}</b></div>
                                        <div id="question2">Weight : <b>{strain.weight}</b></div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                })
            }
            </>
        </div>
    );
}