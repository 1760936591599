import React, { useState, useEffect, useMemo } from 'react';
import './EditProfile.css';
import logo from "./Green-2.jpeg";
import backArrow from "./Arrow_left.png";
import Select from 'react-select';
import countries_states from "./countries-states.json";
import { useNavigate, useLocation } from "react-router-dom";
import { db, storage } from "./../firebase-config.js";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 } from "uuid";

export const EditProfile = () => {

    const location = useLocation();
    const [user_id, setUID] = useState("");
    const navigate = useNavigate();

    // working data
    const [name, setName] = useState("Enter name");
    const [email, setEmail] = useState("Enter email");
    const [age, setAge] = useState(-1);
    const [country, setCountry] = useState("USA");
    const [state, setState] = useState("CO");
    const [sharer, setSharer] = useState(false);
    const [image, setImage] = useState(logo);
    const [imgFile, setImgFile] = useState(null);

    // funtion to return a usable list of states from the given country
    const statesList = (ctry) => {
        var statesData = countries_states.find(item => item.iso3 === ctry).states;
        var simple_states = [];
        statesData.forEach(state => {
            let updated_state = { value: state.state_code, label: state.name };
            simple_states.push(updated_state);
        });
        return simple_states;
    };

    // build states and countries list to use in dropdowns
    const [states_dropdown, setMyArray] = useState(null);
    var simple_states = statesList(country);
    var simple_countries = useMemo(() => {
        var result = [];
        countries_states.forEach(country => {
            let updated_country = { value: country.iso3, label: country.name };
            result.push(updated_country);
        });
        return result;
    }, []);

    // instantiate data w/ db values
    useEffect(() => {
        async function fetchData() {
            setUID(location.state.uID);
            const user_ref = doc(db, "users", location.state.uID);
            const user = await getDoc(user_ref);

            if (!user.exists()) {
                alert("Something went wrong!");
            }

            // sets working values to db values
            setName(user.data().name);
            setEmail(user.data().email);
            setAge(user.data().age);
            setCountry(user.data().country);
            setState(user.data().state);
            setSharer(user.data().sharer);

            // corrects text in dropdowns to db values (others updated automatically)
            var simple_states = statesList(user.data().country);
            document.getElementById("editState").children[2].children[0].children[0].textContent = simple_states.find(state => state.value === user.data().state).label;
            document.getElementById("editCountry").children[2].children[0].children[0].textContent = simple_countries.find(item => item.value === user.data().country).label;

            // download user's pfp
            listAll(ref(storage, `images/${location.state.uID}/`)).then((response) => {
                let img = response.items.find(item => item.name.split("_")[0] === "pfp");
                getDownloadURL(img).then((url) => {
                    setImage(url);
                }).catch(() => {
                    console.log("Error downloading image!");
                });
            }).catch(() => {
                console.log("No images to find!");
            });
        }
        fetchData();
    }, []);

    // changes state dorpdown when a new country is selected
    const onCountryChange = (value, actionMeta) => {
        let new_country = value.value;
        let simple_new_states = statesList(new_country);
        if (simple_new_states.length === 0) {
            simple_new_states.push({ value: "N/A", label: "Not Applicable"});
        }
        setCountry(new_country);
        setMyArray(simple_new_states);
        document.getElementById("editState").children[2].children[0].children[0].textContent = simple_new_states[0].label;
        setState(simple_new_states[0].value);
    };

    // displays new image when one is uploaded
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setImgFile(event.target.files[0]);
        }
    };

    // saves data to db
    const update = async () => {
        // check for validity
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (name === "" || age === "" || email === "") {
            alert("Please fill in all fields.");
            return;
        } else if (!re.test(email)) {
            alert("Please provide a valid email address.");
            return;
        } else if (age < 21) {
            alert("Please check if your selected age is correct.")
            return;
        }

        const user_ref = doc(db, "users", user_id);
        await updateDoc(user_ref, {
            name: name,
            email: email,
            age: age,
            country: country,
            state: state
        });

        if (imgFile) {
            const pfp_ref = ref(storage, `images/${user_id}/`);
            listAll(pfp_ref).then((response) => {
                let pfp_found = false;
                response.items.forEach((item) => {
                    if (!pfp_found && item.name.split("_")[0] === "pfp") {
                        deleteObject(item);
                        pfp_found = true;
                    }
                });
            }).catch();
            const imgRef = ref(storage, `images/${user_id}/pfp_${v4()}`);
            uploadBytes(imgRef, imgFile);
        }

        // go to home
        home();
    };

    // returns to home page
    async function home() {
        let userid = location.state.uID;
        const docRef = doc(db, "users", userid);
      try { 
        const docSnap = await getDoc(docRef);
        if (docSnap.data().sharer) {
          navigate("/sharerHome", { state: {uID: userid} });
        } else if (!docSnap.data().sharer) {
          navigate("/shareeHome", { state: {uID: userid} });
        }
      } catch(error) { 
        console.log(error);
        navigate("/");
      }
    }

    return(
        <div id='phoneFrame'>
            
            <div id='headerEP'>
                <button id='backButtonEP' onClick={home}>
                    <img id="backPic_EP" src={backArrow} alt="Back"/>
                </button>
                <p id='pageTitleEP'>Edit Profile</p>
            </div>

            <div id='picGroup'>
                <img id="profilePic" src={image} alt="Default" width="125vw" height="125vw"/>
                <label htmlFor="changePic" id="changePicText">
                    Change Profile Picture
                    <input id="changePic" type='file' accept='image/*' onChange={onImageChange}/>
                </label>
            </div>

            <div id='typeGroup'>
                <p id="typeText">You are a {sharer ? "Sharer" : "Sharee"}</p>
            </div>

            <div id='nameGroup' className='inputFieldGroup'>
                <p className='inputField'>Name</p>
                <input id="editName" type="text" placeholder={name} onChange={(event) => {setName(event.target.value)}}/>
            </div>

            <div id='emailGroup' className='inputFieldGroup'>
                <p className='inputField'>Email Address</p>
                <p id="editEmail">{email}</p>
            </div>

            <div id='ageGroup' className='inputFieldGroup'>
                <p className='inputField'>Age</p>
                <input id="editAge" type="number" min="21" placeholder={age} onChange={(event) => {setAge(event.target.value)}}/>
            </div>

            <div id='countryGroup'>
                <Select onChange={onCountryChange}
                        id='editCountry'
                        value={simple_countries.value}
                        options={simple_countries}
                        defaultValue={simple_countries.find(item => item.value === country)}
                        menuPlacement='top'
                        maxMenuHeight={200}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                background: '#eaeaea',
                                marginLeft: '4%',
                                marginRight: '4%',
                                marginTop: '2.5%',
                                textAlign: 'left',
                                paddingLeft: '2.5%',
                                fontSize: '1.25em',
                                color: '#1e1e1e'
                            }),
                        }}
                />
            </div>

            <div id='stateGroup'>
                <Select onChange={(value) => {setState(value.value)}}
                        id='editState'
                        value={states_dropdown == null ? simple_states.value : states_dropdown.value}
                        options={states_dropdown == null ? simple_states : states_dropdown}
                        defaultValue={states_dropdown == null ? simple_states.find(item => item.value === state) : states_dropdown[0]}
                        menuPlacement='top'
                        maxMenuHeight={200}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                background: '#eaeaea',
                                marginLeft: '4%',
                                marginRight: '4%',
                                marginTop: '2.5%',
                                textAlign: 'left',
                                paddingLeft: '2.5%',
                                fontSize: '1.25em',
                                color: '#1e1e1e'
                            }),
                        }}
                />
            </div>

            <input id='updateButton' type="button" value="UPDATE PROFILE" onClick={update}/>

        </div>

    );
}