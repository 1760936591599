import Select from "react-select";
import "./OrderConfirmation.css";
import { getDoc, doc, addDoc, collection, updateDoc, deleteDoc } from "firebase/firestore";
import { db, storage } from "./../firebase-config.js";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app"
import 'firebase/compat/firestore';
import 'firebase/compat/auth'; 
import Popup from "reactjs-popup";
import PayPal from "./PayPal";
import { v4 } from "uuid";
import { ref, uploadBytes, getBytes, listAll } from "firebase/storage";

export const OrderConfirmation = () => {

    const location = useLocation();
    const user_id = location.state.uID;
    const [num, setNum] = useState(null);
    const [pickup, setPickup] = useState(false);
    const [pickupType, setPickupType] = useState("");
    const [currentOrder, setCurrentOrder] = useState("");
    const [timeUp, setTimeUp] = useState(false);
    const [orderSent, setOrderSent] = useState(false);
    const [orderID, setOrderID] = useState(null);
    const ordersRef = collection(db, "orders");
    const popRef = useRef();
    const openPayPal = () => popRef.current.open();
    const closePayPal = () => popRef.current.close();
    const cart = location.state?.cart;
    const sharer = location.state?.sharer;
    const strains = location.state?.strains;
    const eta = location.state?.time;
    const navigate = useNavigate();

    const [time, setTime] = useState(new Date());

    async function OrderListen() {
        if (orderSent && !timeUp) {
            if (currentOrder.accepted) {
                let sellerID = currentOrder.seller;
                let orderID = currentOrder.id;
                let sellerNum = currentOrder.sellerPhone;
                navigate("/shareeOrderPage", {state:{uID: location.state.uID, sellerID: sellerID, orderID: orderID, sellerNum: sellerNum}});
            }
        }
    }

    {OrderListen()}

    if (timeUp && orderID) {
        deleteDoc(doc(db, "orders", orderID));
        navigate("/shareeHome", {state:{uID: location.state.uID}});
    }
 
    useEffect(() => {
        if (!location.state) {
            navigate("/getPot", {state:{uID: location.state.uID}});
        }
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

            return () => clearInterval(interval);
    }, []);

    function CountDown(s){
        try {
            var countdown = s.createdAt.seconds*1000;
            var now = new Date().getTime();
            const elapsed = Math.trunc((120000 - (now - countdown))/1000);
            if (elapsed <= 0) {
                setTimeUp(true);
            }
            return elapsed;
        } catch {
            return -1;
        }
        
    }

    let pType = [{value: "By Bike", label: "By Bike"},
                 {value: "By Public Transport", label: "By Public Transport"},
                 {value: "By Bus", label: "By Bus"},
                 {value: "By Walking", label: "By Walking"},
                 {value: "By Car", label: "By Car"}]

    const back = () => {
        navigate("/selectStrains", {state:{sharer:sharer, strains:strains, uID: location.state.uID}});
    };

    const request = async () => {

        if (!num || !num.match(/^\d{10}$/)) {
            alert("Please input a valid phone number.");
            return;
        }

        const user = await getDoc(doc(db, "users", user_id));
        if (user.data().paid) {
            if (window.confirm("You have paid last time. So, you can request it for free. Please press Yes to request this pot.")) {
                // do not charge the $1
                sendOrder();
                const userDoc = doc(db, "users", user_id);
                const newFields = {paid: false};
                await updateDoc(userDoc, newFields);

            } else {
                return;
            }
        }
        if (user != null) {
            if (!user.data().paid) {
                // openPayPal();
                sendOrder();
                getOrder(user.id)
            } else {
                getOrder(user.id)
            }
            
        }
    };

    const getOrder = async (e) => {
        //Read Orders
        try {
            const docRef = doc(db, "orders", e);
            const ord = await getDoc(docRef);
            const order = ord.data();
            order.id = ord.id;
            setCurrentOrder(order);
        } catch {
        }
        
    }
    
    function checkComplete() {
        try {
            getOrder(currentOrder.id)
            return currentOrder.completed == true;
        } catch {
            console.log("no current user?")
        }
        
    }
    checkComplete()
    const sendOrder = async () => {
        // Stops page from refreshing when form is submitted
        // e.preventDefault();
        
        // Writes new doc to database with all of the parameters: text, createdAt, uid, photoURL
        const idList = []
        let sum = 0;
        let count = 0;
        cart.forEach((str) => {
            idList.push(str.id);
            sum += str.price;
            count += 1
        })
        const newDoc = await addDoc(ordersRef, {
            accepted: false,
            buyer: user_id, 
            seller: (""+sharer.id), 
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            pickup: pickup,
            accepted: false,
            buyerPhone: num,
            completed: false,
            type: pickupType,
            total: sum,
            strainCount: count,
            buyerPhone: num,
            eta: eta
        });

        getOrder(newDoc.id);
        setOrderSent(true);
        setOrderID(newDoc.id);

        alert("Your order has beeen sent, please do not leave this page");

        const userStrain = collection(db, `orders/${newDoc.id}/strains`);
        cart.forEach(async (str) => {
            await addDoc(userStrain, {name: str.name,  
                type: str.type, weight: str.weight})
        })

        // save sharer pfp at -> images/orders/{order.id}/seller_{v4}
        savePFP(newDoc.id, sharer.id, true);

        // save sharee pfp at -> images/orders/{order.id}/buyer_{v4}
        savePFP(newDoc.id, user_id, false);
    }

    const handleChoose = (event) => {
        setPickup(event.target.value !== "Delivery");
    };

    const savePFP = async (orderID, userID, sharer) => {
        // console.log(userID);
        // console.log(sharer ? "buyer" : "seller");
        // download img
        listAll(ref(storage, `images/${userID}/`)).then(async (response) => {
            let img = response.items.find(item => item.name.split("_")[0] === "pfp");
            let imgFile = new File([await getBytes(img)], `${sharer ? "buyer" : "seller"}_${v4()}.jpeg`);

            // save to `images/orders/${orderID}/${sharer ? "buyer" : "seller"}_${v4()}`
            const imgRef = ref(storage, `images/orders/${orderID}/${sharer ? "buyer" : "seller"}_${v4()}.jpeg`);
            uploadBytes(imgRef, imgFile);
        });
    };

    return (
        <div id="phoneFrameOC">

            <div id="headerOC">
                {!orderSent && <button onClick={back}>Back</button>}
                <b>Delivery Type</b>
                <div />
                <div />
            </div>
            <p id="confirmBlurb">
                How do you want to receive your Pot? Do you want to pick it up or have it 
                delivered to you? After your selection you will be connected to meet your Sharer(Seller) 
                after paying the $1 connecting fee. We will then share their location and contact 
                information in order to receive your pot.<br/>Thank you and enjoy!
            </p>
            <div id="phoneInput">
                <p>Valid Contact No.</p>
                <input onChange={(event) => {setNum(event.target.value)}} id="telNum" type="tel" pattern="[0-9]{10}" />
            </div>
            <div id="delChoice">
                <p>Choose One :</p>
                <label>
                    <input onChange={handleChoose} type="radio" id="del" name="choice" value="Delivery" defaultChecked />
                    <p>Delivery</p>
                </label>
                <label>
                    <input onChange={handleChoose} type="radio" id="pickup" name="choice" value="Pick up" />
                    <p>Pick up</p>
                </label> 
            </div>
            <>
                {pickup && <div id="pickupType">
                        <p>How will you get there?</p>
                        <Select id='pickupTypeSelect' 
                                value={pType.value}
                                options={pType}
                                defaultValue={pType[0]}
                                placeholder="Select transport type"
                                onChange={(e) => setPickupType(e.value)}
                        />
                    </div>
                }
            </>
            <div id="feeStatement">
                <b>Connecting Fee :</b>
                <p>
                    Once you press "Request Pot", you will pay the $1 connecting fee. If you paid 
                    and the sharer rejects the request then don't worry, you do not need to pay for 
                    your next request.
                </p>
            </div>
            {
                <div>
                    {!orderSent && <button id="request" onClick={() => request()}>REQUEST POT</button>}
                    <div> 
                    {CountDown(currentOrder) > 0 && <div id="countdown3">Time Remaining: {CountDown(currentOrder)} secs</div>}
                    </div>
                    <Popup
                        trigger={<div/>}
                        ref={popRef}
                        closeOnDocumentClick
                        modal
                    >
                        <PayPal sendOrder={sendOrder} closePayPal={closePayPal}/>
                    </Popup>
                </div>
            } 
        </div>
    );
}