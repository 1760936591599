import {useState} from 'react';
import './CollapsibleDescription.css'

export const CollapsibleDescription = () => {

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);

    const toggle1 = () => {
        setOpen1(!open1);
    }

    const toggle2 = () => {
        setOpen2(!open2);
    }

    return (
        <div id="description1Container">
            <div>
            <button id="descriptionButton1" onClick={toggle1}>+   Want Pot?</button>

            {open1 &&
            <p id="appDescription">
            - Get Your Pot. <br/> <br/>
            - With PotExpress, we will find and connect you with people that have Pot to distribute/share/sell. <br/> <br/>
            - With PotExpress it’s fast, simple, and easy to get the Pot that you want. <br/> <br/>
            - Now you don’t have to know someone that has Pot; no more searching, we do the searching and connecting for you. <br/> <br/>
            - Get the Pot that you want from people that have Pot. <br/> <br/>
            - Have it “Delivered To You” or you can go “Pick it up.”</p>
            }

            </div>

            <div id="descriptionBox2Pair">
            <button id="descriptionButton2" onClick={toggle2}>+   Got Pot?</button>
            {open2 &&
            <p id="appDescription2">
            - Become a Pot Distributor Sharer/Seller.  <br/> <br/>
            - With Pot Express, we will find and connect you with people that want your Pot. <br/> <br/>
            - Also PotExpress is the best way to find and connect with people that want your Pot. <br/> <br/>
            - We bring the people that want Pot to you. <br/> <br/> </p> }
            </div>
        </div>
    );
}