import React from 'react';
import { useState, useEffect, useRef } from 'react';
import './ShareeHomepage.css'
import { useLocation } from "react-router-dom";
import { db } from "./../firebase-config.js"
import { updateDoc, doc} from "firebase/firestore"
import Navbar from './Navbar';

export const ShareeHomepage = () => {
    const [currentLocation, setCurrentLocation] = useState("");
    const [currentLongitude, setCurrentLongitude] = useState(0);
    const [currentLatitude, setCurrentLatitude] = useState(0);
    const [called, setCalled] = useState(false);
    const { state } = useLocation();
    const [userid, setUID] = useState("");
    const foundOrder = useRef(false);

    const updateUser = async (id, address, lat, long) => {
        const userDoc = doc(db, "users", id);
        const newFields = {location: address, latitude: lat, longitude: long};
        await updateDoc(userDoc, newFields);
    }

    useEffect(() => {
        setUID(state.uID);
    }, []);

    const successCallback = (position) => {
        setCurrentLongitude(position.coords.longitude);
        setCurrentLatitude(position.coords.latitude);
        if (!called && (currentLatitude !== 0 || currentLongitude !== 0)) {
            geoCoding(currentLatitude, currentLongitude);
        }
    };
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback);
    }

    async function geoCoding(lat, long) {
        setCalled(true);
        const response = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat.toString() + "," + long.toString() + "&key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc");
        const data = await response.json();
        if (data.status === "OK") {
            setCurrentLocation(data.results[0].formatted_address);
            updateUser(userid, data.results[0].formatted_address, lat, long);
        } else {
            setCurrentLocation("your current location");
            updateUser(userid, "current location", lat, long);
        }
    }

    return(
    <div>
        {!foundOrder.current && <div id="mainShareeHome">
        <div  className="map-responsive3">
        {currentLocation.length == 0 && <iframe src={"https://www.google.com/maps/embed/v1/view?key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc&center=31.000000,-100.000000&zoom=3"}
        width="100vw" height="65vh" style={{border:'0'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>}
        {currentLocation.length != 0 && <iframe src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyAs3HYVP08952sIRnD30b3KQvKKFF5knKc&q=" + currentLatitude.toString() + ", " + currentLongitude.toString()}
        width="100vw" height="65vh" style={{border:'0'}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>}
        </div>

        {currentLocation.length != 0 && <div id="shareeLocationInfo">You are buying from {currentLocation}</div>}
        {currentLocation.length == 0 && <div id="shareeLocationInfo">Please turn on location sharing</div>}
        </div>}

        <div id="shareeNavBar">
            <Navbar userid={userid}/>
        </div>
    </div>
    );
}