import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import './UploadPFPPage.css'
import defaultPic from "./DefaultProfile.png";
import backArrow from "./Arrow_left.png";
import { storage } from "./../firebase-config.js";
import { ref, uploadBytes, listAll, deleteObject } from "firebase/storage";
import { v4 } from "uuid";
import { auth } from './../firebase-config.js';
import { signInWithEmailAndPassword } from "firebase/auth";


export const UploadPFPPage = () => {

    let existing_img = defaultPic;
    const navigate = useNavigate();
    const { state } = useLocation();

    const [user_id, setUID] = useState("");
    const [image, setImage] = useState(null);
    const [imageChanged, setImageChanged] = useState(false);
    const [imgFile, setImgFile] = useState(null);

    useEffect(() => {
        setUID(state.uID);
    }, []);

    const login = async () => {
        try {
          const user = await signInWithEmailAndPassword(
            auth,
            state.email,
            state.pwd
          );
          navigate("/sharerHome", { state: {uID: user_id} });
        } catch (error) {
          console.log(error.message);
          alert("Your email or password was not correct");
        }
      };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setImgFile(event.target.files[0]);
            setImageChanged(true);
        }
    };

    function handleFinishClicked() {
        if (!imageChanged) {
            alert("Please upload a profile picture");
        } else {
            if (imgFile) {
                const pfp_ref = ref(storage, `images/${user_id}/`);
                listAll(pfp_ref).then((response) => {
                    let pfp_found = false;
                    response.items.forEach((item) => {
                        if (!pfp_found && item.name.split("_")[0] === "pfp") {
                            deleteObject(item);
                            pfp_found = true;
                        }
                    });
                }).catch();
                const imgRef = ref(storage, `images/${user_id}/pfp_${v4()}`);
                uploadBytes(imgRef, imgFile);
            }
            login()
        }
    }

    function backClick() {
        navigate("/selectUserType", {state:{uID:state.uID}});
    }

    return(
        <div>
            <div id="uploadBackDiv">
            <button id="uploadPFPBackButton" onClick={backClick}>
                    <img id="uploadPFPBackPic" src={backArrow} alt="Back"/>
                </button>
            </div>
            <div id="pfpTitle">Hey {!state.name ? "User!" : state.name + "!"}</div>

            {/* Images */}
            <div id="selectPic">
                <label id="setPicText" for="file-input">
                    <img id="firstProfilePic" src={image == null ? existing_img : image} alt="Default" width="125vw" height="125vw"/>
                </label>
                <input id="file-input" type="file" accept='image/*' onChange={onImageChange}/>          
            </div>
            
            <div id="pfpu">Upload profile photo</div> 

            <div id="uploadPicText">Upload your picture so that when you meet your Sharer or Sharee they will know its you!</div>

            <div> <button onClick={handleFinishClicked} id="uploadPFPNextButton">Finish</button> </div>
        </div>
        
    );
}