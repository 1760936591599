import React from "react";
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import * as FaIcons from "react-icons/fa"
import * as CgIcons from 'react-icons/cg'
import {BiLogOutCircle} from "react-icons/bi"
import {SlSpeech} from "react-icons/sl"
import {BiChevronUpCircle} from "react-icons/bi"
import {TfiPackage} from "react-icons/tfi"

export const SidebarDataSharee = [
    {
        title: "Home",
        path: "/shareeHome",
        icons: <IoIcons.IoIosHome />,
        cName: "nav-text"
    },
    {
        title: "Get Pot",
        path: "/getPot",
        icons: <AiIcons.AiOutlineFileSearch/>,
        cName: "nav-text"
    },
    {
        title: "Edit Profile",
        path: "/editProfile",
        icons: <FaIcons.FaRegEdit/>,
        cName: "nav-text"
    },
    {
        title: "Order History",
        path: "/orderHistory",
        icons: <CgIcons.CgList/>,
        cName: "nav-text"
    },
    {
        title: "Contact Us",
        path: "/contactUs",
        icons: <SlSpeech/>,
        cName: "nav-text"
    },
    {
        title: "Logout",
        path: "/login",
        icons: <BiLogOutCircle/>,
        cName: "nav-text"
    },
]

export const SidebarDataSharer = [
    {
        title: "Home",
        path: "/sharerHome",
        icons: <IoIcons.IoIosHome />,
        cName: "nav-text"
    },
    {
        title: "Edit Profile",
        path: "/editProfile",
        icons: <FaIcons.FaRegEdit/>,
        cName: "nav-text"
    },
    {
        title: "Manage Strains",
        path: "/manageStrains",
        icons: <TfiPackage />,
        cName: "nav-text"
    },
    {
        title: "Manage Delivery",
        path: "/manageDelivery",
        icons: <BiChevronUpCircle />,
        cName: "nav-text"
    },
    {
        title: "Order History",
        path: "/orderHistory",
        icons: <CgIcons.CgList/>,
        cName: "nav-text"
    },
    {
        title: "Contact Us",
        path: "/contactUs",
        icons: <SlSpeech/>,
        cName: "nav-text"
    },
    {
        title: "Logout",
        path: "/login",
        icons: <BiLogOutCircle/>,
        cName: "nav-text"
    },
]