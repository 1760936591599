import React from 'react';
import './LandingPage.css'
import logo from "./Green-2.jpeg"
import { CollapsibleDescription } from './CollapsibleDescription';
import { PlayStoreLink } from './PlayStoreLink';

export const LandingPage = () => {
    return(
        <div id="landingPageScreen">

            <div id="landingPageTitle">
                <div id="landingHeading"><span>PotExpress</span></div>
                <div id="imgDiv"><img id="potLogo" src = {logo} alt = "Logo 1" width="" height=""/></div>
            </div>

            <div id="collapsibleDescription"><CollapsibleDescription/></div>

            <div id="filler"></div>

            <div> <img src = "https://static.vecteezy.com/system/resources/previews/005/632/792/original/illustration-line-drawing-a-image-of-two-businessmen-shaking-hands-businessperson-negotiations-or-join-business-are-illustrated-by-a-close-handshake-between-two-hand-men-isolated-on-white-background-free-vector.jpg"
                alt = "Logo 2" id="shakingHandsImage"/> </div>

            <div id="descriptionLabel">Use the App</div>

            <div id="descriptionBox">
                <div id="redirectInstructions">Download the App from the Google Play Store, or if you are an Apple user, click the Apple Icon to continue to our Web App!</div>
            </div>

            <div id="playStoreLink"><PlayStoreLink/></div>

        </div>
        /* space for redirect to feedback page
        */

    );
}
